<div class="action-buttons" id="action-buttons">
  <div class="toggle-icon" class="toggle-icon" [Cree8DropdownTriggerFor]="downloadDropdown" placement="bottom"
    placement-tooltip-position="none" [placement-tooltip-offset]="12" (bebopClickOutside)="onCloseDropdown(data)"
    [class.active]="active" (click)="onOpenDropdown(data)">
    <svg class="ico" viewBox="0 0 24 24">
      <use xlink:href="app/common/assets/icons/_sprite.svg#Dots_Vertical"></use>
    </svg>
  </div>
  <cree8-dropdown #downloadDropdown [panelClasses]="['download-dropdown-view']">
    <div class="files action-buttons-list">
      <div class="action-buttons-list-item" *ngIf="actionsList.includes('receive-link')"
        (click)="onAction('receive-link')">
        <span class="list-item">
          <svg class="ico" viewBox="0 0 16 16">
            <use xlink:href="app/common/assets/icons/_sprite.svg#Link_Folder_Receive"></use>
          </svg>
          Receive Link
        </span>
      </div>

      <div class="action-buttons-list-item" *ngIf="actionsList.includes('media-info')" (click)="onAction('media-info')">
        <span class="list-item">
          <svg class="ico" viewBox="0 0 24 24">
            <use xlink:href="app/common/assets/icons/_sprite.svg#Info"></use>
          </svg>
          Media Info
        </span>
      </div>

      <div class="action-buttons-list-item" *ngIf="actionsList.includes('download-file')"
        (click)="onAction('download-file')">
        <span class="list-item">
          <svg class="ico" viewBox="0 0 24 24">
            <use xlink:href="app/common/assets/icons/_sprite.svg#Download_Single"></use>
          </svg>
          Download File
        </span>
      </div>

      <div class="action-buttons-list-item" *ngIf="actionsList.includes('share')" (click)="onAction('share')">
        <span class="list-item">
          <svg class="ico" viewBox="0 0 24 24">
            <use xlink:href="app/common/assets/icons/_sprite.svg#Link"></use>
          </svg>
          Share
        </span>
      </div>

      <div class="action-buttons-list-item" *ngIf="actionsList.includes('notes')" (click)="onAction('notes')">
        <span class="list-item">
          <svg class="ico" viewBox="0 0 24 24">
            <use xlink:href="app/common/assets/icons/_sprite.svg#Notes"></use>
          </svg>
          Notes Link
        </span>
      </div>

      <div class="action-buttons-list-item" *ngIf="actionsList.includes('link')" (click)="onAction('link')">
        <span class="list-item">
          <svg class="ico" viewBox="0 0 24 24">
            <use xlink:href="app/common/assets/icons/_sprite.svg#Link"></use>
          </svg>
          Links
        </span>
      </div>

      <div class="action-buttons-list-item" *ngIf="actionsList.includes('members')" (click)="onAction('members')">
        <span class="list-item">
          <svg class="ico" viewBox="0 0 24 24">
            <use xlink:href="app/common/assets/icons/_sprite.svg#Team"></use>
          </svg>
          Members
        </span>
      </div>

      <div class="action-buttons-list-item" *ngIf="actionsList.includes('settings')" (click)="onAction('settings')">
        <span class="list-item">
          <svg class="ico" viewBox="0 0 24 24">
            <use xlink:href="app/common/assets/icons/_sprite.svg#Settings"></use>
          </svg>
          Settings
        </span>
      </div>

      <!-- <div class="action-buttons-list-item" *ngIf="actionsList().includes('rename')" (click)="onAction('rename')">
        <span class="list-item">
          <svg class="ico" viewBox="0 0 24 24">
            <use xlink:href="app/common/assets/icons/_sprite.svg#Edit"></use>
          </svg>
          Rename
        </span>
      </div> -->

      <div class="action-buttons-list-item" *ngIf="actionsList.includes('archive')" (click)="onAction('archive')">
        <span class="list-item">
          <svg class="ico" viewBox="0 0 24 24">
            <use xlink:href="app/common/assets/icons/_sprite.svg#Archive"></use>
          </svg>
          Archive
        </span>
      </div>

      <div class="action-buttons-list-item" *ngIf="actionsList.includes('retrieve')" (click)="onAction('retrieve')">
        <span class="list-item">
          <svg class="ico" viewBox="0 0 24 24">
            <use xlink:href="app/common/assets/icons/_sprite.svg#Retrieve"></use>
          </svg>
          Retrieve
        </span>
      </div>

      <div class="action-buttons-list-item" (click)="onAction('delete')" *ngIf="actionsList.includes('delete')">
        <span class="list-item delete">
          <svg class="ico" viewBox="0 0 24 24">
            <use xlink:href="app/common/assets/icons/_sprite.svg#Trash"></use>
          </svg>
          {{ buildDeleteLabel() }}
        </span>
      </div>
    </div>
  </cree8-dropdown>
</div>
