import { Component, Input, Output, EventEmitter } from '@angular/core'
import { ICardPrice } from './card-price.model'

@Component({
  selector: 'card-price',
  standalone: false,
  templateUrl: './card-price.component.html',
  styleUrl: './card-price.component.scss',
})
export class CardPriceComponent {
  @Input() data: ICardPrice = {} as ICardPrice
  @Input() inverted = false
  @Output() clickBtn: EventEmitter<ICardPrice> = new EventEmitter<ICardPrice>()

  isDarkTheme(): boolean {
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  }

  checkingTooltip(item: number): string {
    return this.data.uptoDescription[item] || ''
  }

  checkingPlusDescription(item: number): string {
    return this.data.plusDescription[item] || ''
  }

  strokeColor(): string {
    if(this.inverted) {
      return this.data.actived && this.isDarkTheme() ? 'black' : 'white'
    }
    return this.data.actived && this.isDarkTheme() ? 'white' : 'black'
  }

  backgroundColor(): "dark" | "light" {
    if(this.inverted) {
      return this.data.actived && this.isDarkTheme() ? 'light' : 'dark'
    }
    return this.data.actived && this.isDarkTheme() ? 'dark' : 'light'
  }
}
