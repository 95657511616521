<div class="container">
  <div class="loader" *ngIf="isLoading">
    <bebop-loader [status]="'Loading'" [rounded]="false"></bebop-loader>
  </div>

  <div class="transfer-status-container">
    <table class="cree8-table">
      <thead>
        <tr>
          <th>File name</th>
          <th>Source</th>
          <th>Destination</th>
          <th>Action</th>
          <th>Size</th>
          <th>Percentage</th>
          <th>Status</th>
          <th>Last Updated</th>
        </tr>
      </thead>
      <tbody class="scrollbar">
        <tr *ngFor="let file of files">
          <td>{{ file.file }}</td>
          <td>{{ file.syncDirection	== 2 ? 'Glacier' : file.projectName }}</td>
          <td>{{ file.syncDirection	== 2 ? file.projectName : 'Glacier' }}</td>
          <td>
            <span>
              <svg class="ico" viewBox="0 0 24 24" *ngIf="file.syncDirection	== 2">
                <use xlink:href="app/common/assets/icons/_sprite.svg#Retrieve"></use>
              </svg>
              <svg class="ico" viewBox="0 0 24 24" *ngIf="file.syncDirection	!= 2">
                <use xlink:href="app/common/assets/icons/_sprite.svg#Archive"></use>
              </svg>
              {{ file.syncDirection	== 2 ? 'Retrieve' : 'Archive' }}
            </span>
          </td>
          <td>{{ file.readableSize }}</td>
          <td>
            <div class="percentage">
              <svg class="progress-circle" viewBox="0 0 36 36">
                <circle class="progress-background" cx="18" cy="18" r="16"></circle>
                <circle class="progress-bar" cx="18" cy="18" r="16" fill="none"
                  [attr.stroke-dasharray]="calculateStrokeDashArray(file.percentage)"></circle>
              </svg>
              {{ file.percentage }}%
            </div>

          </td>
          <!-- <td>
            <span [ngClass]="getStatusClass(file.status)">{{ file.status }}</span>
          </td> -->
          <td>
            <span class="status" [ngClass]="{
                'bg-warning': file.status === fileStatus.Paused,
                'bg-info': file.status === fileStatus.Uploading ||
                          file.status === fileStatus.Started ||
                          file.status === fileStatus.Queued ||
                          file.status === fileStatus.Downloading ||
                          file.status === fileStatus.Verifying ||
                          file.status === fileStatus.Staged,
                'bg-success': file.status === fileStatus.Completed,
                'bg-primary': file.status === fileStatus.Cancelled,
                'bg-danger': file.status === fileStatus.Error
              }">
              {{ statusNames[file.status] }}
            </span>
          </td>

          <td>{{ file.lastUpdated | date: 'medium' }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<!--

api/v1/s3/list_files

can using fromBucket
amd credential _id
from the project endpoint




-->
