import { Injectable } from '@angular/core'
import { Query } from '@datorama/akita'

import { ArchiveStore, IArchiveState } from './archive.store'

@Injectable({ providedIn: 'root' })
export class ArchiveQuery extends Query<IArchiveState> {
  constructor(protected store: ArchiveStore) {
    super(store)
  }

  selectAll() {
    return this.select((state) => state)
  }

  selectActiveTab() {
    return this.select('activeTab')
  }

  selectProject() {
    return this.select('project')
  }

  selectSearchParams() {
    return this.select('searchParams')
  }
}
