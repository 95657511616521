<div class="bebop-header">
  <div class="header-section">
    <div class="top-view">
      <div class="left-view">
        <div class="org-branding" *ngIf="selectedOrg">
          <img *ngIf="selectedOrg?.logo" class="img-circle" [src]="selectedOrg?.logo" />
          <span class="body3">{{selectedOrg?.name}}</span>
        </div>
        <cree8-indicator-env [identifier]="checkIdentifier()"></cree8-indicator-env>
        <div class="pod-section" *ngIf="podSelect.items.length > 1">
          <bebop-input-select selectOnly="true" placeholder="Select Pod…" [value]="podSelect.value"
            class="header-pod-select">
            <div dropdown-options>
              <bebop-input-select-option *ngFor="let item of podSelect.items" (click)="onSelectPod(item)">
                <div option-text>
                  <div class="name-icon-section">
                    <div class="name-entry">{{ item.value }}</div>

                    <div class="tick-icon" *ngIf="item.selected">
                      <svg viewBox="0 0 16 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M15.168 5.106a.667.667 0 0 0-1.003-.878l-5.833 6.666a.667.667 0 0 0 1.003.878l5.833-6.666Zm-3.333 0a.667.667 0 0 0-1.004-.878l-5.396 6.167L2.436 7.8a.667.667 0 1 0-.872 1.008l3.5 3.03c.277.24.696.211.938-.065l5.833-6.666Z"
                          fill="#2D5DE0" />
                      </svg>
                    </div>
                  </div>
                </div>
              </bebop-input-select-option>
            </div>
          </bebop-input-select>
        </div>

        <ng-container *ngTemplateOutlet="indicator"></ng-container>
      </div>

      <div class="right-view">
        <bebop-notification></bebop-notification>
        <div class="profile-section">
          <bebop-profile></bebop-profile>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #indicator>
  <div class="overview-transfers v2" *ngIf="!environment.browser">
    <div class="icon-wrapper" *ngIf="!activeFlex" (mouseenter)="onMouseEnter($event, 'flex')"
      (mouseleave)="onMouseLeave($event, 'flex')" [bebopDropdownTriggerFor]="fmenuDropdown" placement="bottom" placement-tooltip-position="left"
      placement-overlay-x-position="start" placement-origin-x-position="start" [placement-tooltip-offset]="12" (click)="$event.preventDefault(); $event.stopPropagation()">
      <svg class="icon" viewBox="0 0 24 24">
        <use xlink:href="app/common/assets/icons/_sprite.svg#Lucidlink"></use>
      </svg>

      <bebop-dropdown #fmenuDropdown [panelClasses]="['flex-header-menu-panel']">
        <div class="no-menu-dropdown">
          <div class="title-menu h5">LucidLink sync status:</div>
          <div class="label-menu small">No active lucidlink sync</div>
        </div>
      </bebop-dropdown>
    </div>

    <div class="icon-wrapper" *ngIf="activeFlex" (mouseenter)="onMouseEnter($event, 'flex')"
      (mouseleave)="onMouseLeave($event, 'flex')" (click)="$event.preventDefault(); $event.stopPropagation()"
      [bebopDropdownTriggerFor]="afmenuDropdown" placement="bottom" placement-tooltip-position="left"
      placement-overlay-x-position="start" placement-origin-x-position="start" [placement-tooltip-offset]="12">

      <div class="icon">
        <img [src]="lucidlinkOptions.path" alt="Lucidlink icon" />
      </div>

      <bebop-dropdown #afmenuDropdown [panelClasses]="['flex-header-menu-panel']">
        <div class="list-menu-dropdown">
          <div class="list-item" *ngFor="let p of syncFlexMounts; let i = index">
            <div class="list-type">
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M2.99992 1.66536C2.26354 1.66536 1.66659 2.26232 1.66659 2.9987V10.9987C1.66659 11.7351 2.26354 12.332 2.99992 12.332H10.9999C11.7363 12.332 12.3333 11.7351 12.3333 10.9987V5.2487C12.3333 4.51232 11.7363 3.91536 10.9999 3.91536H7.7135C7.04479 3.91536 6.42033 3.58116 6.0494 3.02477L5.34104 1.96223C5.2174 1.77677 5.00924 1.66536 4.78634 1.66536H2.99992ZM0.333252 2.9987C0.333252 1.52594 1.52716 0.332031 2.99992 0.332031H4.78634C5.45505 0.332031 6.07951 0.666233 6.45044 1.22263L7.1588 2.28516C7.28244 2.47063 7.4906 2.58203 7.7135 2.58203H10.9999C12.4727 2.58203 13.6666 3.77594 13.6666 5.2487V10.9987C13.6666 12.4715 12.4727 13.6654 10.9999 13.6654H2.99992C1.52716 13.6654 0.333252 12.4715 0.333252 10.9987V2.9987Z"
                  fill="#5A5A68" />
              </svg>
            </div>
            <div class="list-name small">{{ p?.name }}</div>

            <div class="dot-pulse"></div>
          </div>
        </div>
      </bebop-dropdown>
    </div>

    <div class="icon-wrapper" *ngIf="!(hotfolder && _hotInfo?.progress != 100)" (mouseenter)="onMouseEnter($event, 'hot')"
      (mouseleave)="onMouseLeave($event, 'hot')" (click)="$event.preventDefault(); $event.stopPropagation()"
      [bebopDropdownTriggerFor]="humenuDropdown" placement="bottom" placement-tooltip-position="left"
      placement-overlay-x-position="start" placement-origin-x-position="start" [placement-tooltip-offset]="12">

      <svg class="icon" viewBox="0 0 24 24">
        <use xlink:href="app/common/assets/icons/_sprite.svg#Watch_Folder"></use>
      </svg>


      <bebop-dropdown #humenuDropdown [panelClasses]="['up-header-menu-panel']">
        <div class="no-menu-dropdown">
          <div class="title-menu h5">Watch folder uploads:</div>
          <div class="label-menu small">No active watch folder</div>
        </div>
      </bebop-dropdown>
    </div>

    <div class="icon-wrapper" *ngIf="hotfolder && _hotInfo?.progress != 100" (mouseenter)="onMouseEnter($event, 'hot')"
      (mouseleave)="onMouseLeave($event, 'hot')" (click)="$event.preventDefault(); $event.stopPropagation()"
      [bebopDropdownTriggerFor]="ahumenuDropdown" placement="bottom" placement-tooltip-position="left"
      placement-overlay-x-position="start" placement-origin-x-position="start" [placement-tooltip-offset]="12">
      <div class="icon">
        <img [src]="watchFolderOptions.path" alt="Watch folder icon" />
      </div>

      <bebop-dropdown #ahumenuDropdown [panelClasses]="['up-header-menu-panel']">
        <div class="menu-dropdown">
          <div class="title-menu h5">
            Hotfolder sync:
            <div class="tr-size">{{ _hotInfo?.totalBytes }}</div>
          </div>
          <div class="label-top small">
            <div class="label-type">Uploaded</div>
            <div class="label-value">
              {{ _hotInfo?.progress }}%
              <div class="label-tr">({{ _hotInfo?.transferredBytes }})</div>
            </div>
          </div>
          <div class="progress-bar">
            <bebop-progress-bar [type]="'hotfolder'" [progress]="_hotInfo?.progress" [total]="100"></bebop-progress-bar>
          </div>
        </div>
      </bebop-dropdown>
    </div>

    <div class="icon-wrapper" *ngIf="!(activeUpload && _uploadInfo?.progress != 100)" (mouseenter)="onMouseEnter($event, 'up')"
      (mouseleave)="onMouseLeave($event, 'up')" (click)="$event.preventDefault(); $event.stopPropagation()"
      [bebopDropdownTriggerFor]="umenuDropdown" placement="bottom" placement-tooltip-position="left"
      placement-overlay-x-position="start" placement-origin-x-position="start" [placement-tooltip-offset]="12">

      <svg class="icon" viewBox="0 0 24 24">
        <use xlink:href="app/common/assets/icons/_sprite.svg#Upload_Single"></use>
      </svg>

      <bebop-dropdown #umenuDropdown [panelClasses]="['up-header-menu-panel']">
        <div class="no-menu-dropdown">
          <div class="title-menu h5">Active uploads:</div>
          <div class="label-menu small">No active uploads</div>
        </div>
      </bebop-dropdown>
    </div>

    <div class="icon-wrapper" *ngIf="activeUpload && _uploadInfo?.progress != 100" (mouseenter)="onMouseEnter($event, 'up')"
      (mouseleave)="onMouseLeave($event, 'up')" (click)="$event.preventDefault(); $event.stopPropagation()"
      [bebopDropdownTriggerFor]="aumenuDropdown" placement="bottom" placement-tooltip-position="left"
      placement-overlay-x-position="start" placement-origin-x-position="start" [placement-tooltip-offset]="12">

      <div class="icon">
        <img [src]="uploadOptions.path" alt="Upload icon" />
      </div>

      <bebop-dropdown #aumenuDropdown [panelClasses]="['up-header-menu-panel']">
        <div class="menu-dropdown">
          <div class="title-menu h5">
            Active uploads:
            <div class="tr-size">{{ _uploadInfo?.totalBytes }}</div>
          </div>
          <div class="label-top small">
            <div class="label-type">Uploaded</div>
            <div class="label-value">
              {{ _uploadInfo?.progress }}%
              <div class="label-tr">({{ _uploadInfo?.transferredBytes }})</div>
            </div>
          </div>
          <div class="progress-bar">
            <bebop-progress-bar [type]="'active'" [progress]="_uploadInfo?.progress" [total]="100"></bebop-progress-bar>
          </div>
        </div>
      </bebop-dropdown>
    </div>

    <div class="icon-wrapper" *ngIf="!(activeDownload && _downloadInfo?.progress != 100)" (mouseenter)="onMouseEnter($event, 'down')"
      (mouseleave)="onMouseLeave($event, 'down')" (click)="$event.preventDefault(); $event.stopPropagation()"
      [bebopDropdownTriggerFor]="dmenuDropdown" placement="bottom" placement-tooltip-position="left"
      placement-overlay-x-position="start" placement-origin-x-position="start" [placement-tooltip-offset]="12">
      <svg class="icon" viewBox="0 0 24 24">
        <use xlink:href="app/common/assets/icons/_sprite.svg#Download_Single"></use>
      </svg>
      <bebop-dropdown #dmenuDropdown [panelClasses]="['down-header-menu-panel']">
        <div class="no-menu-dropdown">
          <div class="title-menu h5">Active downloads:</div>
          <div class="label-menu small">No active downloads</div>
        </div>
      </bebop-dropdown>
    </div>

    <div class="icon-wrapper" *ngIf="activeDownload && _downloadInfo?.progress != 100" (mouseenter)="onMouseEnter($event, 'down')"
      (mouseleave)="onMouseLeave($event, 'down')" (click)="$event.preventDefault(); $event.stopPropagation()"
      [bebopDropdownTriggerFor]="admenuDropdown" placement="bottom" placement-tooltip-position="left"
      placement-overlay-x-position="start" placement-origin-x-position="start" [placement-tooltip-offset]="12">
      <div class="icon">
        <img [src]="downloadOptions.path" alt="Download icon" />
      </div>

      <bebop-dropdown #admenuDropdown [panelClasses]="['up-header-menu-panel']">
        <div class="menu-dropdown">
          <div class="title-menu h5">
            Active downloads:
            <div class="tr-size">{{ _downloadInfo?.totalBytes }}</div>
          </div>
          <div class="label-menu small">
            <div class="label-top small">
              <div class="label-type">Downloaded</div>
              <div class="label-value">
                {{ _downloadInfo?.progress }}%
                <div class="label-tr">({{ _downloadInfo?.transferredBytes }})</div>
              </div>
            </div>
            <div class="progress-bar">
              <bebop-progress-bar [type]="'download'" [progress]="_downloadInfo?.progress"
                [total]="100"></bebop-progress-bar>
            </div>
          </div>
        </div>
      </bebop-dropdown>
    </div>
  </div>
</ng-template>
