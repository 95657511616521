import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core'
import { Router } from '@angular/router'

import { debounceTime, Subject, takeUntil } from 'rxjs'
import { Cree8Modal } from 'src/app/common/components/cree8-modal/cree8-modal.component'
import { ToastService } from 'src/app/common/components/toast/toast.service'
import { ListUsers, Project, User } from 'src/app/models/bebop.model'
import { MainService } from 'src/app/services/main.service'
import { ProjectsService } from 'src/app/services/projects.service'
import { UserService } from 'src/app/services/user.service'
import { ProjectsQuery } from 'src/app/store/projects/projects.query'
import { ProjectsService as ProjectsStoreService } from 'src/app/store/projects/projects.service'
import { isErrorResponse } from 'src/app/utils/response-utils'

@Component({
  selector: 'cree8-project-files-users',
  styleUrls: ['./project-files-users.component.scss'],
  templateUrl: './project-files-users.component.html',
})
export class ProjectFilesUsersComponent implements OnInit, OnDestroy {
  @Input() assign = true
  @Input() showSearch = false
  @Input() organization: any
  private project: Project

  modalSetting: Cree8Modal = { counting: 0, current: 0, title: '', type: 'confirmation' }
  MODAL_UNASSIGN_TITLE = 'Unassign User'
  isLoading = false

  listUsers: ListUsers = { data: [], hasMore: false }
  allListUsers: ListUsers = { data: [], hasMore: false }
  filteredUsers: User[] = []
  searchValue = ''
  sortValue = ''
  selectedMember = { id: '', name: '' }

  private searchSubject = new Subject<string>()
  private destroy$ = new Subject()

  constructor(
    private projectsService: ProjectsService,
    private projectsStoreService: ProjectsStoreService,
    private toastService: ToastService,
    private mainService: MainService,
    private projectQuery: ProjectsQuery,
    private userService: UserService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.projectQuery
      .getSelectedProject()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.project = res

        this.loadUsers()
      })

    this.searchSubject
      .pipe(debounceTime(300), takeUntil(this.destroy$))
      .subscribe((value) => this.applyFilters(value, this.sortValue))
  }

  loadUsers() {
    this.isLoading = true
    this.userService
      .getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        error: (err) => {
          this.toastService.show({ text: err.error.msg, type: 'error' })
          this.isLoading = false
        },
        next: (users) => {
          this.listUsers = { ...users }
          this.allListUsers = { ...users }
          this.filteredUsers = [...(users?.data || [])]
          this.isLoading = false
        },
      })
  }

  searchUser(value: string) {
    this.searchValue = value
    this.searchSubject.next(value)
  }

  sortingMembers(sort: string) {
    this.sortValue = sort
    this.applyFilters(this.searchValue, sort)
  }

  private applyFilters(searchTerm: string, sort: string) {
    let filtered = this.allListUsers.data

    if (searchTerm) {
      filtered = filtered.filter((user) => user.name.toLowerCase().includes(searchTerm.toLowerCase()))
    }

    if (sort === '-date_created') {
      filtered = filtered.sort((a, b) => (a.date_created > b.date_created ? -1 : 1))
    } else if (sort === 'date_created') {
      filtered = filtered.sort((a, b) => (a.date_created < b.date_created ? -1 : 1))
    } else if (sort === 'name') {
      filtered = filtered.sort((a, b) => a.name.localeCompare(b.name))
    } else if (sort === '-name') {
      filtered = filtered.sort((a, b) => b.name.localeCompare(a.name))
    }

    this.filteredUsers = filtered
  }

  private listAssignUsers(): User[] {
    return (
      this.filteredUsers.filter((el) =>
        this.project?.users.some((user) => (typeof user === 'string' ? user === el._id : user._id === el._id))
      ) || []
    )
  }

  private listUnAssignUsers(): User[] {
    return (
      this.filteredUsers.filter(
        (el) => !this.project?.users.some((user) => (typeof user === 'string' ? user === el._id : user._id === el._id))
      ) || []
    )
  }

  getListMappingUsers(): User[] {
    return this.assign ? this.listAssignUsers() : this.listUnAssignUsers()
  }

  assignUser(userId: string, status: boolean, event: Event) {
    event.stopPropagation()
    this.selectedMember.id = userId
    this.selectedMember.name = this.listUsers.data.find((user) => user._id === userId)?.name || ''

    if (!status) {
      this.addUserToProject(userId)
      return
    }

    this.modalSetting.title = this.MODAL_UNASSIGN_TITLE
    this.modalSetting.labelConfirm = 'YES'
  }

  addUserToProject(id): void {
    this.projectsService
      .addUserToProject(this.project._id, id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        if (isErrorResponse(response)) {
          this.toastService.show({ text: 'Error while assigning user to project', type: 'error' })
        } else {
          this.toastService.show({ text: 'User assigned to project', type: 'success' })
          this.project = response
          this.loadUsers()
        }
      })
  }

  removeUserFromProject(id): void {
    this.projectsService
      .deleteUserFromProject(this.project._id, id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.modalSetting.title = ''
        if (isErrorResponse(response)) {
          this.toastService.show({ text: 'Error while unassigning user to project', type: 'error' })
        } else {
          this.toastService.show({ text: 'User unassigned from project', type: 'success' })
          // this.project = response
          this.projectsStoreService.setSelectedProject(response)
        }
      })
  }

  getUserImage(user: any): any {
    // Get user image based on the activity's created_by
    if (user) {
      return this.mainService.getUserImage(user)
    } else {
      return this.mainService.getSystemIcon()
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  onCloseModal() {
    this.modalSetting.title = ''
  }

  onSubmitModal() {
    this.removeUserFromProject(this.selectedMember.id)
  }

  navigateToProfile(id: string): void {
    if (this.assign) this.router.navigate([`app/profile/${id}`])
  }

  getDetailProject() {
    this.isLoading = true
    this.projectsService
      .getProject(this.project._id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.project = response.data
        this.isLoading = false
      })
  }
}
