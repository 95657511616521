import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'

import { Subject, takeUntil } from 'rxjs'
import { ModalOverlayRef } from 'src/app/common/classes/modal-overlay-ref'
import { ToastService } from 'src/app/common/components/toast/toast.service'
import { ComponentModalService } from 'src/app/common/services/component-modal.service'
import { RocketSession, UiDownloadFile } from 'src/app/components/rocket/common/classes/rocket-types'
import {
  SelectRocketUploadPathAction,
  SelectRocketUploadPathComponent,
} from 'src/app/components/rocket/uploads/modals/select-rocket-upload-path/select-rocket-upload-path.component'
import { ArchiveService } from 'src/app/services/archive.service'
import { ProjectsService } from 'src/app/services/projects.service'
import { DownloaderLifecycleService } from 'src/app/services/rocket/download/downloader-lifecycle.service'
import { ArchiveQuery } from 'src/app/store/archive/archive.query'
import { ArchiveService as ArchiveStoreService } from 'src/app/store/archive/archive.service'
import { UIQuery } from 'src/app/store/ui/ui.query'
import { isErrorResponse } from 'src/app/utils/response-utils'

@Component({
  selector: 'cree8-archive-view',
  styleUrls: ['./archive-view.component.scss'],
  templateUrl: './archive-view.component.html',
})
export class ArchiveViewComponent implements OnInit, OnDestroy {
  @Output() breadcrumbChange = new EventEmitter<any[]>()
  @Input() breadcrumbs: { name: string; prefix?: string }[] = []
  @Input() project: any

  private destroy$ = new Subject<void>()
  items: any[] = [] // Combined list of files and folders
  currentPrefix = '/' // Initial prefix
  currentPage = 1 // Pagination current page
  hasMore = false // Flag to indicate more files are available
  isLoading = false // Loading indicator
  bucketName = ''
  credential = ''
  viewType = 'grid'
  search = ''
  session: RocketSession<any, UiDownloadFile>
  changeLocationRef$: ModalOverlayRef<SelectRocketUploadPathComponent, SelectRocketUploadPathAction>
  isLoadingMore = false
  page = 1
  maxKeys = 50
  activeFolderPrefix = ''

  constructor(
    private archiveService: ArchiveService,
    private archiveStoreService: ArchiveStoreService,
    private archiveQuery: ArchiveQuery,
    private projectsService: ProjectsService,
    private uiQuery: UIQuery,
    private modalService: ComponentModalService,
    private lcService: DownloaderLifecycleService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.uiQuery
      .getSelectedOrg()
      .pipe(takeUntil(this.destroy$))
      .subscribe((org) => {
        if (!org) {
          return
        }

        this.session = this.lcService.newRocketSession(org)

        this.archiveQuery
          .selectSearchParams()
          .pipe(takeUntil(this.destroy$))
          .subscribe((params) => {
            if (params.credential && params.bucketName) {
              this.bucketName = params.bucketName
              this.currentPrefix = params.prefix
              this.currentPage = params.currentPage
              this.credential = params.credential
              this.loadFilesAndFolders(this.currentPrefix, this.currentPage)
              this.updateBreadcrumb(this.currentPrefix)
            }
          })
      })

    console.log(this.project)
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  // Load files and folders based on prefix and page
  loadFilesAndFolders(prefix: string, page: number): void {
    if (this.isLoading) {
      return // Prevent duplicate requests
    }
    this.isLoading = true
    this.archiveService
      .listFiles({
        bucketName: this.bucketName,
        credentialId: this.credential,
        maxKeys: this.maxKeys,
        page,
        prefix,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: any) => {
        if (!isErrorResponse(response)) {
          // Map folders and add type "folder"
          const folders = response.folderList.map((folder: any) => ({
            name: folder.Prefix.split('/').slice(-2, -1)[0], // Extract folder name
            prefix: folder.Prefix,
            type: 'folder',
          }))

          // Map files and add type "file"
          const files = response.fileList
            .filter((file: any) => file.Key !== prefix) // Exclude current folder key
            .map((file: any) => {
              const keyParts = file.Key.split('/')
              const fullFileName = keyParts[keyParts.length - 1]
              const fileNameParts = fullFileName.split('.')
              const name = fullFileName
              const fileType = fileNameParts[fileNameParts.length - 1]

              return {
                ...file,
                fileType,
                name,
                type: 'file',
              }
            })

          // Combine files and folders into a single list
          this.items = [...folders, ...files]
          this.hasMore = response.hasMore
        }
        this.isLoading = false
        this.isLoadingMore = false
      })
  }

  // Handle folder click
  onFolderClick(prefix: string): void {
    this.archiveStoreService.updateSearch({
      bucketName: this.bucketName,
      credential: this.credential,
      currentPage: 1,
      prefix,
    })
    // this.loadFilesAndFolders(this.currentPrefix, this.currentPage)
    this.updateBreadcrumb(prefix)
  }

  // Load more files for pagination
  loadMoreFiles(): void {
    if (this.hasMore && !this.isLoadingMore) {
      this.isLoadingMore = true
      this.currentPage++
      this.loadFilesAndFolders(this.currentPrefix, this.currentPage)
    }
  }

  retrieveFileOrFolder(file: any, ev?: Event): void {
    this.changeLocationRef$ = this.modalService.open<SelectRocketUploadPathComponent, SelectRocketUploadPathAction>(
      SelectRocketUploadPathComponent,
      {
        animateFrom: ev?.target as Element,
        data: {
          archive: this.project.solutions?.archive,
          project: this.project,
          session: this.session,
          title: 'Select project and folder to retrieve files to',
        },
        hasBackdrop: true,
      },
      {
        hasBackdropClick: false,
        hasEscapeClose: false,
        isCentered: true,
      }
    )

    this.changeLocationRef$.events().subscribe((e) => {
      if (e?.name == 'Cancel' || e?.name == 'Close') {
        return
      }

      const storage = this.project.storage
      let rootPath = [storage.transferServerMount, storage.directories.projects, this.project.folderName].join('/')

      if (this.project.solutions?.osOneFlex) {
        // Handle osOneFlex solution
        rootPath = [
          storage.transferServerMount,
          this.project.organization.flexMountCredential?.credentials?.lucid?.root_mount || '',
          storage.directories.projects,
          this.project.folderName,
        ].join('/')
      } else if (this.project.solutions?.osOneBlock) {
        // Handle osOneBlock solution
        rootPath = [
          storage.transferServerMount,
          this.project.organization.osOneBlockRootPath || '',
          storage.directories.projects,
          this.project.folderName,
        ].join('/')
      }

      if (!e.project) {
        // show notification
        this.toastService.show({
          text: 'No project selected',
          type: 'error',
        })
        return
      }

      let fullPath = rootPath.replace(/[/]?$/, '/') + e.path
      let relativePath = e.path || '/'

      // Call API to create sync settings in the selected project with the selected file/folder
      let retriveToProject = e.project

      this.projectsService
        .saveSyncSetting(retriveToProject._id, {
          credentials: this.project.credentials,
          fromBucket: this.project.fromBucket,
          sync_active: true,
          sync_down_delete: false,
          sync_filters: { blacklist_file_tags: [], blacklist_folder_tags: [], file_tags: [], folder_tags: [] },
          sync_scheduler: { type: 'INTERVAL', value: 'ALWAYS' },
          sync_up_delete: false,
          sync_up_delete_file_only: false,
          syncDestination: relativePath,
          syncDirection: 'down',
          syncFiles: [file.prefix || file.Key],
        })
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          error: () => {
            this.toastService.show({
              text: 'Request to fetch file/folder failed',
              type: 'error',
            })
          },
          next: (res) => {
            this.toastService.show({
              text: 'Request queued, the transfer monitor will show the progress in a minute',
              type: 'success',
            })
          },
        })
    })
  }

  onActionFile(props: { type: string; data: any }, file: any) {
    if (file.type === 'folder' && props.type === 'detail') {
      this.activeFolderPrefix = file.prefix
      this.onFolderClick(file.prefix)
      return
    }

    if (props.type === 'retrieve') {
      this.retrieveFileOrFolder(file)
      return
    }
  }

  private updateBreadcrumb(prefix: string): void {
    const segments = prefix.split('/').filter((seg) => seg)
    this.breadcrumbs = [
      { name: 'All', prefix: 'root' }, // Base path
      { name: this.project?.name || 'Unknown Project', prefix: '' }, // Dynamic project name
    ]
    let accumulatedPrefix = ''
    segments.forEach((segment) => {
      accumulatedPrefix += segment + '/'
      this.breadcrumbs.push({ name: segment, prefix: accumulatedPrefix })
    })

    this.breadcrumbChange.emit(this.breadcrumbs)
  }

  onRefresh() {
    this.loadFilesAndFolders(this.currentPrefix, this.currentPage)
  }

  onSearchFilename(s: string) {
    this.search = this.activeFolderPrefix.length > 0 ? `${this.activeFolderPrefix}${s}` : s
    this.loadFilesAndFolders(this.search, this.currentPage)
  }

  onViewType(type: string) {
    this.viewType = type
  }
}
