import { Component, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { Subject, takeUntil } from 'rxjs'
import { IActionType } from 'src/app/common/components/actions-list/actions-list.component'
import { ITabs } from 'src/app/common/components/cree8-tabs/cree8-tabs.component'
import { LinkType, Project } from 'src/app/models/bebop.model'
import { ProjectsService } from 'src/app/services/projects.service'
import { ArchiveQuery } from 'src/app/store/archive/archive.query'
import { ArchiveService as ArchiveStoreService } from 'src/app/store/archive/archive.service'
import { ProjectsService as ProjectsStoreService } from 'src/app/store/projects/projects.service'
import { DownloaderService } from 'src/app/store/rocket/downloader/downloader.service'
import { UIQuery } from 'src/app/store/ui/ui.query'

import { ProjectFilesUsersComponent } from '../projects-detail/project-files-users/project-files-users.component'

import { ArchiveTransferStatusComponent } from './archive-transfer-status/archive-transfer-status.component'
import { ArchiveViewComponent } from './archive-view/archive-view.component'

@Component({
  selector: 'cree8-archive-detail',
  styleUrl: './archive-detail.component.scss',
  templateUrl: './archive-detail.component.html',
})
export class ArchiveDetailComponent {
  @ViewChild(ArchiveViewComponent) archiveViewComponent!: ArchiveViewComponent
  @ViewChild(ArchiveTransferStatusComponent) archiveTransferStatusComponent!: ArchiveTransferStatusComponent
  @ViewChild(ProjectFilesUsersComponent) projectFilesUsersComponent!: ProjectFilesUsersComponent
  tabs: ITabs[] = [
    { active: true, label: 'Files', value: 'files' },
    { label: 'Members', value: 'members' },
    { label: 'Transfer status', value: 'transferStatus' },
  ]
  breadcrumbs: { name: string; prefix: string }[] = [{ name: 'All', prefix: 'root' }]

  _destroy$ = new Subject()
  organization: any
  project: any
  projectId: string | undefined = undefined
  activeTab = this.tabs[0].value

  listSortingProjects: { label: string; value: string }[] = [
    { label: 'Newest to oldest', value: '-date_created' },
    { label: 'Oldest to newest', value: 'date_created' },
    { label: 'From A to Z', value: 'name' },
    { label: 'From Z to A', value: '-name' },
  ]

  activeSorting: string = this.listSortingProjects[0].value
  activedViewType = 'grid'
  allowedActions: IActionType[] = ['search', 'refresh', 'view-type']

  constructor(
    private projectsService: ProjectsService,
    private archiveStoreService: ArchiveStoreService,
    private projectsStoreService: ProjectsStoreService,
    private downloaderService: DownloaderService,
    private router: Router,
    private route: ActivatedRoute,
    private uiQuery: UIQuery
  ) {}

  ngOnInit(): void {
    this.uiQuery
      .getSelectedOrg()
      .pipe(takeUntil(this._destroy$))
      .subscribe((org) => {
        // Ensure org exists
        if (!org) {
          return
        }

        if (this.organization && this.organization._id !== org._id && this.projectId) {
          this.router.navigate(['/app/projects'])
        }

        this.organization = org
        this.renderDetail()
      })
  }

  renderDetail() {
    this.route.queryParams.pipe(takeUntil(this._destroy$)).subscribe((res) => {
      if (res['activeTab']) {
        this.activeTab = res['activeTab']
        this.setActiveTab(this.activeTab)
      }
      if (this.projectId == res['projectId']) return

      this.projectId = res['projectId']
      this.projectsService
        .getProject(this.projectId)
        .pipe(takeUntil(this._destroy$))
        .subscribe((response) => {
          this.project = response.data
          this.breadcrumbs = [
            { name: 'All', prefix: 'root' }, // Base path
            { name: this.project?.name || 'Unknown Project', prefix: '' }, // Dynamic project name
          ]

          const paths = this.project.fromBucket.split('/')

          this.archiveStoreService.updateSearch({
            bucketName: paths[0],
            credential: this.project.credentials[0]._id,
            currentPage: 1,
            prefix: paths[1] ? paths[1] + '/' : '',
          })
          this.archiveStoreService.setProject(this.project)
          this.projectsStoreService.setSelectedProject(this.project)
        })
    })
  }

  setActiveTab(value: string) {
    this.tabs.forEach((tab) => {
      tab.active = tab.value === value
    })

    if (value === 'files') {
      this.allowedActions = ['search', 'refresh', 'view-type']
    } else if (value === 'members') {
      this.allowedActions = ['search', 'refresh']
    } else {
      this.allowedActions = ['search', 'refresh']
    }
  }

  onSelectHeaderTabs(value: string) {
    this.router.navigate([], {
      queryParams: { activeTab: value },
      queryParamsHandling: 'merge',
    })
  }

  // Update breadcrumbs when the child emits the change
  onBreadcrumbChange(newBreadcrumbs: any[]): void {
    this.breadcrumbs = newBreadcrumbs
  }

  // Navigate to a specific breadcrumb directory
  onGoDirectory(segment: { name: string; prefix: string }): void {
    // Handle breadcrumb navigation logic, e.g., pass prefix back to child
    if (segment.prefix === 'root') {
      this.downloaderService.clear()
      this.projectsStoreService.clear()
      this.archiveStoreService.clear()
      this.router.navigate(['/app/projects'])
    } else {
      this.archiveStoreService.updatePrefix(segment.prefix)
    }
  }

  onRefresh() {
    if (this.activeTab === 'files') {
      this.archiveViewComponent.onRefresh()
      return
    }

    if (this.activeTab === 'transferStatus') {
      this.archiveTransferStatusComponent.onRefresh()
      return
    }

    if (this.activeTab === 'members') {
      this.projectFilesUsersComponent.loadUsers()
      return
    }
  }

  onSearchValue(searchText: string) {
    if (this.activeTab === 'files') {
      this.archiveViewComponent.onSearchFilename(searchText)
      return
    }

    if (this.activeTab === 'transferStatus') {
      this.archiveTransferStatusComponent.onSearchValue(searchText)
      return
    }

    if (this.activeTab === 'members') {
      this.projectFilesUsersComponent.searchUser(searchText)
      return
    }
  }

  onSorting(sorting: string) {}

  onViewType(viewType: string) {
    this.activedViewType = viewType
    this.archiveViewComponent.onViewType(viewType)
  }

  onCreatedProject() {}

  onScroll(event: Event): void {
    const target = event.target as HTMLElement
    const scrollTop = target.scrollTop
    const scrollHeight = target.scrollHeight
    const clientHeight = target.clientHeight

    // Check if user has scrolled to the bottom
    if (scrollTop + clientHeight >= scrollHeight - 100) {
      if (this.activeTab === 'transferStatus') {
        this.archiveTransferStatusComponent.onLoadMore()
      }

      if (this.activeTab === 'files') {
        this.archiveViewComponent.loadMoreFiles()
      }
    }
  }
}
