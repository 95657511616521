import { Injectable } from '@angular/core'
import { Store, StoreConfig } from '@datorama/akita'

export type TAction = 'refresh' | 'search' | 'sort' | 'init'

export interface IArchiveState {
  activeTab: string
  project: any
  searchParams: {
    prefix: string
    bucketName: string
    currentPage: number
    credential: string
  }
}

export function createInitialState(): IArchiveState {
  return {
    activeTab: 'files',
    project: null,
    searchParams: {
      bucketName: '',
      credential: '',
      currentPage: 1,
      prefix: '',
    },
  }
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'archive-store', resettable: true })
export class ArchiveStore extends Store<IArchiveState> {
  constructor() {
    super(createInitialState())
  }
}
