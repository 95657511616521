<div [ngClass]="generateClass">
  <div class="header" [class.empty]="header.length === 0">
    {{ header }}
  </div>
  <div [ngClass]="generateClassBody">
    <ng-content></ng-content>
    <div class="footer" *ngIf="footer">
      <ng-content select="[footer-card]"></ng-content>
    </div>
  </div>
</div>
