/* eslint-disable sort-keys-fix/sort-keys-fix */
import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { AppLayoutComponent } from './components/app-layout/app-layout.component'
import { BroadcastsComponent } from './components/broadcasts/broadcasts.component'
import { DashboardComponent, EarthDashboardComponent } from './components/dashboard'
import { LayoutComponent } from './components/layout/layout.component'
import { LoginComponent } from './components/login/login.component'
import { ProfileComponent } from './components/profile/profile.component'
import { ArchiveDetailComponent } from './components/projects/pages/archive-detail/archive-detail.component'
import { ProjectsDetailComponent } from './components/projects/pages/projects-detail/projects-detail.component'
import { ProjectsViewComponent } from './components/projects/pages/projects-view/projects-view.component'
import { ProjectsComponent } from './components/projects/projects.component'
import { AppSettingsComponent, OrganizationSettingsComponent, SettingsComponent } from './components/settings'
import { InvitesComponent, MembersComponent, TeamComponent, UnassignedComponent } from './components/team'
import { UpdaterComponent } from './components/updater/updater.component'
import { TabWorkstationsComponent } from './components/workstations/tabs/tab-workstations/tab-workstations.component'
import { WorkstationsComponent } from './components/workstations/workstations.component'
import { AppGuard } from './guards/app-guard'

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivateChild: [AppGuard],
    children: [
      {
        path: '',
        component: LoginComponent,
        canActivate: [],
        data: { animation: 'Login' },
      },
      {
        path: 'update',
        component: UpdaterComponent,
        canActivate: [],
        data: { animation: 'Splash' },
      },
      {
        path: 'app',
        component: AppLayoutComponent,
        children: [
          {
            path: 'profile/:id',
            component: ProfileComponent,
          },
          {
            path: 'dashboard',
            component: DashboardComponent,
            canActivate: [],
            data: { animation: 'Dashboard' },
          },
          {
            path: 'dashboard-earth',
            component: EarthDashboardComponent,
            canActivate: [],
            data: { animation: 'DashboardEarth' },
          },
          {
            path: 'workstations',
            component: WorkstationsComponent,
            canActivate: [],
            data: { animation: 'Workstations' },
            children: [
              {
                path: 'all',
                component: TabWorkstationsComponent,
                canActivate: [],
                data: { tabKind: 'All', animation: 'WorkstationsAll' },
              },
              {
                path: 'favs',
                component: TabWorkstationsComponent,
                canActivate: [],
                data: { tabKind: 'Favorite', animation: 'WorkstationsFavs' },
              },
              {
                path: 'running',
                component: TabWorkstationsComponent,
                canActivate: [],
                data: { tabKind: 'Running', animation: 'WorkstationsRunning' },
              },
              {
                path: 'not-started',
                component: TabWorkstationsComponent,
                canActivate: [],
                data: { tabKind: 'Not-Started', animation: 'WorkstationsNotStarted' },
              },
            ],
          },
          {
            path: 'projects',
            component: ProjectsComponent,
            canActivate: [],
            data: { animation: 'Projects' },
            children: [
              {
                path: '',
                component: ProjectsViewComponent,
                canActivate: [],
              },
              {
                path: 'view',
                component: ProjectsDetailComponent,
                canActivate: [],
                children: [],
              },
              {
                path: 'archive',
                component: ArchiveDetailComponent,
                canActivate: [],
                children: [],
              },
            ],
          },
          {
            path: 'team',
            component: TeamComponent,
            canActivate: [],
            data: { animation: 'Team' },
            children: [
              {
                path: '',
                redirectTo: 'members',
                pathMatch: 'full',
              },
              {
                path: 'members',
                component: MembersComponent,
                canActivate: [],
              },
              {
                path: 'invites',
                component: InvitesComponent,
                canActivate: [],
              },
              {
                path: 'unassigned',
                component: UnassignedComponent,
                canActivate: [],
              },
            ],
          },
          {
            path: 'broadcast',
            component: BroadcastsComponent,
            canActivate: [],
            data: { animation: 'Broadcast' },
          },
          {
            path: 'org-profile',
            component: OrganizationSettingsComponent,
            canActivate: [],
            data: { animation: 'Settings' },
          },
          {
            path: 'settings',
            component: AppSettingsComponent,
            canActivate: [],
            data: { animation: 'Settings' },
          },
        ],
      },
    ],
  },

  /* TODO: Add error page for route not found
  {
    path: '**',
    redirectTo: '/error',
    pathMatch: 'full'
  }
  */
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true /*, enableTracing: true */ })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
