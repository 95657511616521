import { Injectable } from '@angular/core'

import { ToastType } from '../common/components/toast/toast.types'
import { ComponentModalService } from '../common/services/component-modal.service'
import { UiBebopLink } from '../components/rocket/common/classes/rocket-types'
import {
  AppInstallType,
  NoAppWorkstationAction,
  NoAppWorkstationComponent,
} from '../components/workstation/modals/no-app-workstation/no-app-workstation.component'
import {
  BebopLink,
  CastOperator,
  LucidCacheRootFolder,
  LucidDefaults,
  LucidRootPath,
  Project,
  User
} from '../models/bebop.model'
import { FlexPin, UiProject } from '../models/ui.model'

import { BebopClientUtilsService } from './bebop-client-utils.service'
import { BebopConfigService } from './bebop-config.service'
import { ElectronService } from './electron.service'
import { UserService } from './user.service'

// TODO user may keep it somewhere else - get it through input ?
export const LUCID_PATH_OSX = '/Applications/Lucid.app/Contents/MacOS/LucidApp'
export const LUCID_PATH_WIN = 'c:\\Program Files\\Lucid\\LucidApp.exe'

export const PARSEC_PATH_OSX = '/Applications/Parsec.app/Contents/MacOS/parsecd'
export const PARSEC_PATH_WIN = 'c:\\Program Files\\Parsec\\parsecd.exe'

export const BEBOP_BROADCAST_PATH_OSX = '/Applications/BeBopBroadcaster.app/Contents/MacOS/BeBopBroadcaster'
export const BEBOP_BROADCAST_PATH_WIN = 'c:\\Program Files\\BeBopBroadcaster\\BeBopBroadcaster.exe'
export const BEBOP_BROADCAST_PATH_OSX_DEV = '/Applications/BeBopBroadcaster-Dev.app/Contents/MacOS/BeBopBroadcaster-Dev'
export const BEBOP_BROADCAST_PATH_WIN_DEV = 'c:\\Program Files\\BeBopBroadcaster-Dev\\BeBopBroadcaster-Dev.exe'

export const WIN_CMD_NOT_FOUND = 'is not recognized as internal or external command'
export const WIN_WHERE_NOT_FOUND = 'Could not find files for the given pattern(s)'

export const WIN_DRIVES = 'EFGHIJKLMNOPQRSTUVWXYZ'.split('').map((m) => `${m}:`) // start with E as in project's settings

export type LucidCommandOptions =
  | 'help'
  | 'version'
  | 'daemon'
  | 'info'
  | 'fscheck'
  | 'status'
  | 'log'
  | 'log-config'
  | 'link'
  | 'unlink'
  | 'mount'
  | 'unmount'
  | 'exit'
  | 'cache'
  | 'prefetcher'
  | 'pin'
  | 'lock'
  | 'compression'
  | 'sync'
  | 'init-s3'
  | 'init-azure'
  | 'rekey-s3'
  | 'rekey-azure'
  | 'password'
  | 'update'
  | 'snapshot'
  | 'snapshot-schedule'
  | 'user'
  | 'group'
  | 'share'
  | 'config'
  | 'perf'
  | 'latency'
  | 'support'
  | 'service'

@Injectable({
  providedIn: 'root',
})
export class ExecutableService {
  constructor(
    private electronService: ElectronService,
    private bebopConfig: BebopConfigService,
    private modalService: ComponentModalService,
    private clientUtils: BebopClientUtilsService,
    private userService: UserService
  ) {}

  appNotAvailable(channel: AppInstallType, elm?: Element) {
    // TODO - add modal
    console.log('app not available', channel)

    let ref = this.modalService.open<NoAppWorkstationComponent, NoAppWorkstationAction>(
      NoAppWorkstationComponent,
      {
        animateFrom: elm,
        data: {
          type: channel,
        },
        hasBackdrop: true,
      },
      {
        hasBackdropClick: true,
        hasEscapeClose: true,
        isCentered: true,
      }
    )
    ref.once().subscribe(({ name }) => {})
    return ref
  }

  get isWindows() {
    return this.electronService.isWindows
  }

  get isDevMode() {
    return this.electronService.isDevMode
  }

  get parsecBasePath() {
    return this.isWindows ? PARSEC_PATH_WIN : PARSEC_PATH_OSX
  }

  get lucidBasePath() {
    return this.isWindows ? LUCID_PATH_WIN : LUCID_PATH_OSX
  }

  async win32Where(pattern: string, opts?: string) {
    if (!this.electronService.isWindows) return

    let cmd = `where ${opts || ''} ${pattern}`

    return this.execute(cmd)
  }

  async isExeAvailableAsync(path: string) {
    let fs = this.electronService.fs
    return new Promise((r, _) =>
      fs.access(path, fs.constants.F_OK, (err) => {
        console.log('@isExeAvailableAsync: ', path, err)
        r(!err)
      })
    )
  }

  async isLucidAvailableAsync() {
    // Use lucid binary test instead of GUI path lookup
    let out = await this.execute('lucid2') // check for lucid2 first and fallback to lucid
    if (out.exitCode == 0) return true

    out = await this.execute('lucid')
    return out.exitCode == 0
    // return this.isExeAvailableAsync(this.lucidBasePath)
  }

  async isParsecAvailableAsync() {
    return this.isExeAvailableAsync(this.parsecBasePath)
  }

  isJumpAvailable() {
    return !!this.electronService.getAppNameForProtocol('jump')
  }

  async isJumpAvailableAsync() {
    return this.isJumpAvailable()
  }

  isPcoIPExternalAvailable() {
    // TODO: check client/browser
    return true || !!this.electronService.app.getApplicationNameForProtocol('pcoip://')
  }

  async isPcoIPExternalAvailableAsync() {
    return this.isPcoIPExternalAvailable()
  }

  get broadcasterBasePath() {
    const dev = this.bebopConfig.isDevelopment

    if (this.isWindows) {
      return dev ? BEBOP_BROADCAST_PATH_WIN_DEV : BEBOP_BROADCAST_PATH_WIN
    }

    return dev ? BEBOP_BROADCAST_PATH_OSX_DEV : BEBOP_BROADCAST_PATH_OSX
  }

  async isBebopBroadcasterAvailableAsync() {
    return this.isExeAvailableAsync(this.broadcasterBasePath)
  }

  openParsecCommand(peerId: string | number) {
    return `"${this.parsecBasePath}" peer_id=${peerId}`
  }

  openJumpCommand(params: { deviceId: string | number; userName: string; displayName: string }) {
    return `jump://?protocol=fluid&NeuronId=${params.deviceId}&DisplayName=${params.displayName}&username=${params.userName}`
  }

  openPcoIPCommand(params: { cgEndpoint: string; desktopUri: string; launchFullScreenSession: boolean }) {
    //Setup PCoIP SDK
    let basePath = ''
    if (!this.isDevMode && process.resourcesPath) basePath = process.resourcesPath + '/pcoip_osx/'
    else basePath += './resources/pcoip_osx/'

    let desktopUri = this.electronService.decrypt(params.desktopUri, this.bebopConfig.settings?.mcpClientTransmitPass)

    if (desktopUri instanceof Error) return new Error('Bad connection string.')

    let queryData = this.electronService.url.parse(desktopUri, true).query
    let newCgEndpoint = params.cgEndpoint.replace('https://', '')

    //local dev..
    //basePath = elecApp.getAppPath() + '/pcoip_osx/';

    //-branding-package bebop_branding.bp -branding-hash 4155c3e798cd63f5be9fd3c8ce73c87ce26fa0ccbccfd2f97d8ff751cf40ce53
    let cmd =
      '"' +
      basePath +
      'cree8_session" ' +
      ' -f -i ' +
      newCgEndpoint +
      ' -s ' +
      queryData.sni +
      ' -t ' +
      queryData['connect-tag'] +
      ' -session-id ' +
      queryData['session-id']
    let sessionFullscreen = params.launchFullScreenSession ? '-f' : ''
    //let cmd = `"${basePath}PCoIPClient.app/Contents/MacOS/PCoIPClient" ${sessionFullscreen} --address ${newCgEndpoint} --sni ${queryData.sni} --connect-tag ${queryData['connect-tag']} --session-id ${queryData['session-id']}`
    //cmd += ' -branding-package bebop_branding.bp -branding-hash 4155c3e798cd63f5be9fd3c8ce73c87ce26fa0ccbccfd2f97d8ff751cf40ce53';
    //console.log(cmd);

    if (this.isWindows) {
      if (!this.isDevMode && process.resourcesPath) basePath = process.resourcesPath + '\\pcoip_win\\'
      else {
        let path = this.electronService.path
        let execPath = '.\\resources' //path.dirname(process.execPath)
        basePath = execPath + '\\pcoip_win\\'
      }
      cmd = `"${basePath}pcoip_client_cli" ${sessionFullscreen} --address ${newCgEndpoint} --sni ${queryData.sni} --connect-tag ${queryData['connect-tag']} --session-id ${queryData['session-id']}`
    }

    return cmd
  }

  openPcoIPExternalCommand(params: { cgDNS: string; token: string }) {
    return `pcoip://${params.cgDNS}/connect?data=${params.token}`
  }

  openBebopBroadcasterCommand(params: { stream: CastOperator; user: User }) {
    let basePath = this.broadcasterBasePath
    let stream = params.stream
    let rtmpLink = stream?.castInbound?.endpoint + '/' + stream?.castInbound?.streamKey

    // TODO pass ?
    let broadcasterAppPass = 'bJcuaDSbCN53b8E4EFVa'
    let streamToken = this.electronService.encrypt(rtmpLink, broadcasterAppPass)

    let broadcasterOpenCmd = basePath
    if (this.isWindows) broadcasterOpenCmd = '"' + basePath + '"' //Quotes for windows, Program Files

    broadcasterOpenCmd += ' --args --tkn=' + streamToken

    if (params.user?.userPreferences?.openDevTools) broadcasterOpenCmd += ' --stz=true'

    return broadcasterOpenCmd
  }

  async getMacCPUInfo() {
    try {
      let out = await this.electronService.execSync('sysctl -n machdep.cpu.brand_string')
      return Promise.resolve(out.toString())
    } catch (e) {
      Promise.resolve('unknown')
    }
  }

  // lucid commands

  async _execute(cmd: string) {
    let cp = this.electronService.exec(cmd, (error: any, stdout: string, stderr: string) => {
      if (error || stderr) {
        console.error('Lucid error', error || stderr)
      }
    })

    return new Promise<boolean>((r, _) => cp.on('exit', (code: number, signal: NodeJS.Signals) => r(code == 0)))
  }

  async getLucidCommand() {
    let base = 'lucid2'
    let out = await this.execute('lucid2') // check for lucid2 first and fallback to lucid

    if (out.exitCode != 0) base = 'lucid'

    return base
  }

  async getLucidBaseCommand(opts: { instanceId?: string | number; command?: LucidCommandOptions; qs?: string }) {
    let base = 'lucid2'
    let out = await this.execute('lucid2') // check for lucid2 first and fallback to lucid

    if (out.exitCode != 0) base = 'lucid'

    return `${base} ${opts.instanceId ? '--instance ' + opts.instanceId : ''} ${opts.command} ${opts?.qs ?? ''}`
  }

  // size string must be like 10MB, 1GB, 5TB
  // options - https://support.lucidlink.com/support/solutions/articles/31000155912-configure-filespace-settings
  async lucidConfigureCacheAsync(opts: { instanceId?: string | number; size?: string; location?: string }) {
    if (!(await this.isLucidAvailableAsync())) return false

    // set size or location
    if (!opts.size && !opts.location) return false

    let keys = opts.size ? ` --DataCache.Size ${opts.size}` : ''
    // we maintain root path from local storage, no need to use lucid config which writes to
    // lucid.json file with rootPath property and causing issues when the path is not found
    // keys += opts.location ? ` --DataCache.Location ${opts.location}` : ''

    // --local / --global / --effective
    // let cmd = `${this.getLucidBaseCommand({ command: 'config' })} --set --global ${keys}`
    let lucidBaseCommand = await this.getLucidBaseCommand({ command: 'config', instanceId: opts.instanceId })
    let cmd = `${lucidBaseCommand} --set ${keys}`

    return this._execute(cmd)
  }

  async lucidExecutableCommand(opts: {
    instanceId?: string | number | number
    command: LucidCommandOptions
    cmdParts?: string
  }) {
    if (!(await this.isLucidAvailableAsync())) return null
    let lucidBaseCommand = await this.getLucidBaseCommand({ command: opts.command, instanceId: opts.instanceId })
    return `${lucidBaseCommand} ${opts.cmdParts ?? ''}`
  }

  // wait until exec exits
  async execute(cmd: string) {
    let sout = ''
    let err = ''
    let serr = ''

    return new Promise<{ exitCode: number; stderr: string; stdout: string; error: string; cmd?: string }>((resolve) => {
      let cp = this.electronService.exec(cmd, (error: any, stdout: string, stderr: string) => {
        if (error || stderr) {
          console.log('exec command error', cmd, error || stderr)
        }

        if (error) {
          err += error.message
        }

        if (stdout) {
          sout += stdout?.toString() ?? ''
        }

        if (stderr) {
          serr += stderr?.toString() ?? ''
        }

        resolve({
          cmd,
          error: err,
          exitCode: cp?.exitCode,
          stderr: serr,
          stdout: sout,
        })
      })
    })

    // sometimes exit emits before exec callback!
    // let exitCode = await new Promise<number>((r, _) => cp.on('exit', (code: number, signal: NodeJS.Signals) => r(code)))

    // return {
    //   exitCode,
    //   stderr: serr,
    //   stdout: sout,
    //   error: err,
    // }
  }

  async lucidExecutable(opts: { instanceId?: string | number; command: LucidCommandOptions; cmdParts?: string }) {
    let cmd = await this.lucidExecutableCommand(opts)
    if (!cmd) return null

    return this.execute(cmd)
  }

  async lucidMountCommand(opts: {
    mountIndex: string | number
    mountStrEnc: string
    project: Project
    isLink?: boolean
    rootPath: string
    ev?: (type: ToastType, message: string, kind: 'RESET-CACHE-PATH' | string) => any
    params?: { resetLocalFs?: boolean }
  }) {
    if (!(await this.isLucidAvailableAsync())) return null

    let lucidFsLinkCmd = this.electronService.decrypt(
      opts.mountStrEnc,
      this.bebopConfig.settings?.mcpClientTransmitPass
    )

    if (lucidFsLinkCmd instanceof Error) {
      return new Error('Bad connection string.')
    }

    let tokens = lucidFsLinkCmd.replace(/\s+/, ' ').split(' ')
    let { fsName, rootPoint } = tokens?.reduce(
      (acc, t) => {
        if (t == '--fs') {
          acc.fsName = '--fs'
          return acc
        } else if (t == '--root-point') {
          acc.rootPoint = '--root-point'
          return acc
        }

        let props = ''
        if (acc.fsName == '--fs') props = 'fsName'
        else if (acc.rootPoint == '--root-point') props = 'rootPoint'

        if (props) acc[props] = t?.replace(/["']/g, '') ?? ''

        return acc
      },
      { fsName: '', rootPoint: '' }
    )

    let lucidCommand = await this.getLucidCommand()

    lucidFsLinkCmd = lucidFsLinkCmd.replace('lucid daemon', `${lucidCommand} daemon --instance ` + opts.mountIndex)

    let rpath = opts.rootPath || ''
    let fs = this.electronService.fs
    if (opts.rootPath) {
      await fs.promises.access(opts.rootPath, fs.constants.R_OK | fs.constants.W_OK).catch((e) => {
        opts.ev?.(
          'warning',
          `${opts?.project?.name}: cache location is not accessible. Switch to default`,
          'RESET-CACHE-PATH'
        )
        rpath = ''
      })

      if (!rpath) {
        // remove old mapping happens thru `opts.ev(..., 'RESET-CACHE-PATH')`

        rpath = this.getLucidDefaultCachePath()
        // remove HOME/.lucid/instance_{$$$}/lucid.json
        // no need to patch lucid.json - this is not the latest configuration
        let idx = +opts.mountIndex
        let suffix = idx < 10 ? `00${idx}` : idx < 100 ? `0${idx}` : `${idx}`
        await this.electronService.fs.promises
          .unlink(this.electronService.path.join(rpath, `instance_${suffix}`, 'lucid.json'))
          .catch((e) => console.error('Unable to unlink lucid.json ', e.message))
      }

      if (rpath && rpath?.indexOf(LucidCacheRootFolder) == -1)
        rpath = this.electronService.path.join(rpath, LucidCacheRootFolder)
    }

    // protect password

    let tempPath = this.electronService.path.join(
      this.electronService.getPath('temp'),
      opts.project?._id || Date.now() + ''
    )

    let [_, right] = lucidFsLinkCmd.split('--password "')

    let pwd = right.substring(0, right.indexOf('"'))

    let pwdToken = `--password "${pwd}"`

    let written = await this.electronService.fs.promises
      .writeFile(tempPath, pwd, { encoding: 'utf8' })
      .then((x) => true)
      .catch((e) => false)

    if (written) {
      lucidFsLinkCmd = lucidFsLinkCmd.replace(pwdToken, '')

      if (this.isWindows) {
        lucidFsLinkCmd = `powershell -command "type "${tempPath}" | ${lucidFsLinkCmd}"`
      } else {
        lucidFsLinkCmd = `cat "${tempPath}" | ${lucidFsLinkCmd}`
      }
    } else {
      console.error('Unable to write pwd to temp file. use command line directly')
    }

    let volSuffix = opts.isLink ? `l${opts.mountIndex}` : ''
    let [front, back] = lucidFsLinkCmd.split('--root-point')

    let cmd = front

    let preferredLetter = opts.project?.preferredLetter?.toUpperCase()
    let winLetter = { assigned: `${preferredLetter}:`, preferred: `${preferredLetter}:` }

    if (this.isWindows) {
      let allDrives = await this.electronService.getWindowsDrives()
      console.log('All available drives: ', allDrives)
      if (allDrives.includes(winLetter.assigned)) {
        // assign new drive
        winLetter.assigned = WIN_DRIVES.find((d) => !allDrives.includes(d))
      }

      cmd += ' --mount-point ' + `"${winLetter.assigned}"`
      cmd += ` --label "${opts.project?.folderName + volSuffix}"`
    } else {
      cmd += ` --mount-point "/Volumes/${opts.project?.folderName + volSuffix}"`
    }

    if (rpath) cmd += ` --root-path "${rpath}" `
    else cmd += ` --root-path "${this.getLucidDefaultCachePath()}"`

    lucidFsLinkCmd = `${cmd} --root-point ${back}`

    if (opts?.params?.resetLocalFs) {
      lucidFsLinkCmd += ' --reset-local-fs'
    }

    opts.project.mountedIndex = opts.mountIndex

    // console.log('[Mount] CMD:', lucidFsLinkCmd)

    return {
      // remove temp file
      cleanup: () => {
        if (written) {
          this.electronService.fs.promises.unlink(tempPath).catch((e) => {})
        }
      },

      fsName,

      linkCommand: lucidFsLinkCmd,

      rootPoint,

      winLetter,
    }
  }

  async lucidUnmountCommand(instanceId: string | number) {
    return this.lucidExecutableCommand({
      command: 'unmount',
      instanceId,
    })
  }

  async lucidUnmount(instanceId: string | number) {
    return this.lucidExecutable({
      command: 'unmount',
      instanceId,
    })
  }

  async lucidVersion() {
    return this.lucidExecutable({
      command: 'version',
    })
  }

  async lucidExit(instanceId: string | number) {
    return this.lucidExecutable({
      command: 'exit',
      instanceId,
    })
  }

  async lucidStatus(instanceId: string | number) {
    let output = await this.lucidExecutable({
      command: 'status',
      instanceId,
    })

    // Sample output
    // Client state: Linked
    // Filespace object store state: connected
    // Filespace object store download latency: low
    // Filespace object store upload latency: low
    // Filespace service state: connected
    // Filespace file index: up-to-date
    // Filespace name: fsflexdevaws.bbpflexdev
    // Filespace id: 2c53f7aa-a114-477a-892b-0a878f7b9f2c
    // Filespace block size: 256KiB
    // Filespace encryption mode: full encryption
    // Filespace bucket name: bbpflexdev
    // Filespace compression: lz4
    // Filespace maximum file size: 512TiB
    // Filespace object store bucket name: bbpflexdev
    // Filespace object store protocol: https
    // Filespace object store provider: AWS
    // Filespace object store type: s3
    // Filespace object store region: us-west-1
    // Filespace object store endpoint: s3.us-west-1.amazonaws.com
    // Filespace object store virtual addressing: off
    // Filespace object store location constraint: on
    // Filespace mode: live
    // Filespace version: 3
    // Root directory: /Users/<username>/.lucid/instance_001/
    // Mount point: /Volumes/bbpflexdefault3671
    // Root point: bbproot/projects/bbpflexdefault3671
    // Daemon mode: application
    // User: lucid\mcpuser
    // Known service version: 2.0.3687

    let lines = output?.stdout?.split(this.electronService.os.EOL)?.filter((x) => x)

    let recs: { [key: string]: string } = lines.reduce(
      (acc, m) => {
        let t = m.split(': ')
        if (t?.length == 0) return acc

        acc[t?.[0].trim()] = t?.[1]?.trim?.() ?? undefined
        return acc
      },
      { ALL: output?.stdout }
    )

    return recs
  }

  async lucidPinFile(instanceId: string | number, file: string) {
    return await this.lucidExecutable({
      cmdParts: `--set "${file}"`,
      command: 'pin',
      instanceId,
    })
  }

  async lucidUnPinFile(instanceId: string | number, file: string) {
    return await this.lucidExecutable({
      cmdParts: `--unset "${file}"`,
      command: 'pin',
      instanceId,
    })
  }

  async lucidUnPinAll(instanceId: string | number) {
    return await this.lucidExecutable({
      cmdParts: `--unset --all`,
      command: 'pin',
      instanceId,
    })
  }

  async lucidPinnedList(instanceId: string | number): Promise<FlexPin[]> {
    let output = await this.lucidExecutable({
      cmdParts: '--list',
      command: 'pin',
      instanceId,
    })

    //
    // PATH                                   STATE
    // g4dn.4xlarge.PugetBench Results.pdf    Pinned

    return (
      output?.stdout
        ?.trim?.()
        ?.split?.(this.electronService.os.EOL)
        ?.slice?.(1)
        ?.filter((x) => x && x.match(/\s+(?:Pinned|Pinning|Failed)\s*$/))
        ?.map((l) => l.replace(/\s+(Pinned|Pinning|Failed)\s*$/, '|$1'))
        ?.map((l) => l.split('|'))
        ?.map((l) => <FlexPin>{ file: l[0], status: l[1] }) ?? []
    )
  }

  getLucidCacheLocationFromData(d: { [key: string]: string }) {
    let rd = d?.['Root directory']?.trim()
    let fid = d?.['Filespace id']?.trim()

    if (rd && fid) return this.electronService.path.join(rd, fid, 'cache')

    return ''
  }

  getLucidRootPath(d: { [key: string]: string }) {
    return d?.['Root directory']?.trim() ?? ''
  }

  getLucidDefaultCachePath() {
    let home = this.electronService.getPath('home')
    return this.electronService.path.join(home, LucidCacheRootFolder)
  }

  async deleteLucidDefaultCachePath() {
    let p = this.getLucidDefaultCachePath()

    return this.electronService.fs.promises
      .rm(p, {
        force: true,
        maxRetries: 1,
        recursive: true,
        retryDelay: 200,
      })
      .catch((e) => console.log('[rm] failed', e.message))
  }

  getLucidRootPathKey() {
    let id = this.userService.id
    return `${LucidRootPath}.${id}`
  }

  getLucidMountedIndex(f: UiProject | UiBebopLink) {
    return f?.isLink || (<BebopLink>f?.source)?.project
      ? (<BebopLink>f?.source)?.project?.mountedIndex
      : f?.source?.mountedIndex
  }

  async deleteLucidCachePath(item: UiProject | UiBebopLink) {
    let lucidPath = this.getLucidRootPathKey()
    let m = this.clientUtils.getLocalStorageItem(lucidPath, false) ?? {}
    let dp = this.getLucidDefaultCachePath()

    let p = m?.[item._id]
    let idx = +this.getLucidMountedIndex(item) || 0
    let suffix = idx < 10 ? `00${idx}` : idx < 100 ? `0${idx}` : `${idx}`
    let instanceOfLabel = `instance_${suffix}`

    let rmdirs: string[] = [this.electronService.path.join(dp, instanceOfLabel)]
    if (p) {
      rmdirs.push(this.electronService.path.join(p, LucidCacheRootFolder, instanceOfLabel))
    }

    let dups: Record<string, boolean> = {}
    for (let rmdir of rmdirs) {
      if (dups[rmdir]) continue
      dups[rmdir] = true
      await this.electronService.fs.promises
        .rm(rmdir, {
          force: true,
          maxRetries: 1,
          recursive: true,
          retryDelay: 200,
        })
        .catch((e) => console.log('[rm] failed', e.message))
    }
  }

  async deleteLucidAllCachePath() {
    let lucidPath = this.getLucidRootPathKey()
    let m = this.clientUtils.getLocalStorageItem(lucidPath, false) ?? {}
    let dp = this.getLucidDefaultCachePath()
    let dupFilter: Record<string, boolean> = {}

    let ps = (<string[]>Object.values(m))
      ?.filter((x) => x)
      ?.map((x) => this.electronService.path.join(x, LucidCacheRootFolder))
      ?.filter((x) => x != dp)
      ?.map((p) => {
        if (dupFilter[p]) return
        dupFilter[p] = true
        return p
      })
      ?.filter((x) => x)

    await this.deleteLucidDefaultCachePath()

    for (let p of ps) {
      await this.electronService.fs.promises
        .rm(p, {
          force: true,
          maxRetries: 1,
          recursive: true,
          retryDelay: 200,
        })
        .catch((e) => console.log('[rm] failed', e.message))
    }
  }

  async lucidConfigDirectory(instanceId: string | number) {
    let output = await this.lucidExecutable({
      command: 'info',
      instanceId,
    })

    let lines = output?.stdout?.split(this.electronService.os.EOL)

    let lable = 'Config directory: '

    let line = lines.find((l) => l?.indexOf(lable) > -1)

    if (line) return line.substring(lable.length)

    return ''
  }

  async lucidConfigInfo(instanceId: string | number) {
    let output = await this.lucidExecutable({
      command: 'config',
      instanceId,
    })

    // output is a fixed width
    let lines = output?.stdout?.split(this.electronService.os.EOL)?.filter((x) => x)
    lines?.splice(0, 1)

    let recs: { [key: string]: string } = lines.reduce((acc, m) => {
      let t = m.split(/\s+/)
      t?.splice(-2, 2)
      if (t?.length > 2) t?.splice(1, 1)

      if (t?.length == 0) return acc

      acc[t?.[0]] = t?.[1] ?? undefined
      return acc
    }, {})

    return recs
  }

  async lucidSync(instanceId: string | number) {
    return this.lucidExecutable({
      command: 'sync',
      instanceId,
    })
  }

  async lucidCacheDrop(instanceId: string | number) {
    return this.lucidExecutable({
      cmdParts: `--drop`,
      command: 'cache',
      instanceId,
    })
  }

  async lucidCacheReset(instanceId: string | number) {
    return this.lucidConfigureCacheAsync({
      instanceId,
      location: this.electronService.path.join(this.electronService.os.homedir(), LucidCacheRootFolder),
      size: `${LucidDefaults.cacheSize}`,
    })
  }

  async lucidCacheDetails(instanceId: string | number) {
    let output = await this.lucidExecutable({
      command: 'cache',
      instanceId,
    })

    let lines = output?.stdout?.split(this.electronService.os.EOL)

    let lineMap = lines.reduce((acc, line) => {
      let [key, value] = line.split(': ')
      acc[key] = value
      return acc
    }, {})

    // State: enabled
    // Backend writes: enabled
    // Page size: 257KiB
    // Maximum configured size: 2.93GiB
    // Current size on disk: 2.93GiB
    // Pinned size on disk: 0B
    // Available size for pinning: 2.34GiB
    // Remaining upload: 0B

    return {
      availableSizeForPinning: lineMap['Available size for pinning'],
      cacheLimit: lineMap['Cache limit'],
      currentSizeOnDisk: lineMap['Current size on disk'],
      maxConfiguredSize: lineMap['Maximum configured size'],
      output,
      pageSize: lineMap['Page size'],
      pinnedSizeOnDisk: lineMap['Pinned size on disk'],
      // 80%?
      remainingUpload: lineMap['Remaining upload'],

      state: lineMap['State'],

      writes: lineMap['Backend writes'],
    }
  }

  lucidLinkPath(l: UiProject | UiBebopLink) {
    if (l.statusDetails?.['Mount point']) {
      return l.statusDetails?.['Mount point']
    }

    if (l.source?.mountPoint) return l.source?.mountPoint
    let project = l.isLink ? (<BebopLink>l.source)?.project : <Project>l.source

    return this.isWindows
      ? `${project?.preferredLetter}:\\`
      : `/Volumes/${project?.folderName}${l.isLink ? 'l' + project.mountedIndex : ''}`
  }
}
