<!-- <div class="folder-list">
  <div *ngFor="let folder of folders" class="folder-item" (click)="onFolderClick(folder.prefix)">
    📁 {{ folder.name }}
  </div>
</div>

<div class="file-list">
  <div *ngFor="let file of files" class="file-item">
    {{ file.name }} ({{ file.fileType }})
  </div>
</div>

<div *ngIf="hasMore" class="load-more">
  <button (click)="loadMoreFiles()" [disabled]="isLoading">
    Load More
  </button>
</div> -->


<div class="container">

  <div class="loader" *ngIf="isLoading">
    <bebop-loader [status]="'Loading'" [rounded]="false"></bebop-loader>
  </div>

  <div class="file-gallery-entries" [class.files-grid]="viewType === 'grid'" [class.files-list]="viewType === 'list'">
    <div *ngFor="let file of items; let i = index; let isFirst = first; let isLast = last"
      [class.last]="i == items?.length - 1" [class.first]="i == 0">
      <cree8-card-file [isArchive]="true" [type]="viewType" [isFirst]="isFirst" [isLast]="isLast"
        (actionFile)="onActionFile($event, file)" [file]="file"></cree8-card-file>
    </div>
  </div>

  <no-data [title]="'No files yet?'" [icon]="'Folder_Single'"
    [description]="'There are no files in this folder yet. You can upload files via the CREE8 client or create more folders.'"
    *ngIf="items?.length < 1 && !isLoading"></no-data>
</div>
