<footer>
  <website-container>
    <div class="cta">
      <img
        src="assets/img/footer.png"
        alt="Start creating anything, anywhere today"
      />
      <div class="content">
        <div class="display2 title-footer">Start creating anything, anywhere today.</div>
        <div class="button">
          <cree8-website-cta-book></cree8-website-cta-book>
        </div>
      </div>
    </div>

    <div class="footer-nav">
      <div class="nav">
        <div class="links">
          <span class="headline2">Company</span>
          <ul>
            <li *ngFor="let link of listFooterLinks">
              <a class="body3" title="{{ link.title }}" (click)="onClickLinks(link.href)">{{
                link.title
              }}</a>
            </li>
          </ul>
        </div>

        <div class="links">
          <span class="headline2">CREE8</span>
          <ul>
            <li *ngFor="let link of links">
              <a
                class="body3"
                (click)="onGenerateLink(link.href)"
                [title]="link.title"
                [class.disabled]="disableMobileLink(link.href)"
                >{{ link.title }}</a
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="dnb">
        <div class="social">
          <span class="headline2">Follow Us</span>
          <social-media></social-media>
        </div>
        <div class="image-dnb">
            <iframe
            [src]="safeIframeSrc"
            width="120px"
            height="100px"
            frameborder="0"
          >
          </iframe>
          <img src="assets/img/AICPA-SOC.png" class="aicpa" alt="AICPA-SOC" />
        </div>
      </div>
    </div>
  </website-container>
</footer>

<div class="cta-container">
  <cree8-website-cta-book></cree8-website-cta-book>
</div>
