import { isPlatformBrowser } from "@angular/common";
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router } from "@angular/router";

@Component({
  selector: "website-footer",
  templateUrl: "./website-footer.component.html",
  styleUrl: "./website-footer.component.scss",
})
export class WebsiteFooterComponent implements OnInit, AfterViewInit {
  @Input() directLink: boolean = false;
  @ViewChild("scriptContainer") scriptContainer: ElementRef;
  safeIframeSrc: SafeResourceUrl;

  listFooterLinks = [
    {
      title: "Terms of service",
      href: "terms",
    },
    {
      title: "Privacy policy",
      href: "privacy-policy",
    },
    {
      title: "Newsroom",
      href: "newsroom",
    },
  ];

  links = [
    {
      title: "Pricing",
      href: "pricing",
    },
    {
      title: "Get the app",
      href: "get-the-app",
    },
    {
      title: "Sign up",
      href: "https://store.cree8.io/",
    },
  ];

  constructor(
    private router: Router,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId: Object,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    const iframeSrc =
      "https://profiles.dunsregistered.com/DRSUSBadge.aspx?duns=13c5a392396c7ccc414fc91d3b59c511&ProfileURL=https://www.dnb.com/business-directory/company-profiles.cree8_inc.13c5a392396c7ccc414fc91d3b59c511.html?referrer=DRS";
    this.safeIframeSrc =
      this.sanitizer.bypassSecurityTrustResourceUrl(iframeSrc);
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        this.loadDnBScript();
      }, 1000);
    }
  }


  onGenerateLink(href: string) {
    if (href === "get-the-app") {
      const userAgent = navigator.userAgent.toLowerCase();

      // Check if the device is mobile
      const isMobile =
        /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
          userAgent
        );

      if (isMobile) {
        // Do nothing or show a message for mobile users
        console.log("App download is not available on mobile devices");
        return;
      }

      let downloadLink = "";

      if (userAgent.includes("win")) {
        downloadLink = "https://api.cree8.io/installers/firefly/win";
      } else if (userAgent.includes("mac")) {
        downloadLink = "https://api.cree8.io/installers/firefly/osx";
      } else {
        console.log("App download is not available on os devices");
        return;
      }

      window.open(downloadLink, "_blank");
      return;
    }

    if(href === 'pricing'){
      if(window.location.pathname !== '/'){
        window.location.replace(`/#pricing`);
        return;
      }
      const element = document.getElementById('pricing');
      element.scrollIntoView({ behavior: 'smooth' });
      return;
    }

    window.location.replace(`${href}`);
  }

  onGetStarted() {
    window.location.replace("https://store.cree8.io/");
  }

  onClickLinks(href: string) {
    if (this.directLink) {
      this.router.navigate([href]);
      return;
    }

    window.open(`https://cree8.io/${href}`, '_blank');
  }

  disableMobileLink(href: string): boolean {
    if (href === "get-the-app") {
      const userAgent = navigator.userAgent.toLowerCase();
      // Check if the device is mobile
      const isMobile =
        /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
          userAgent
        );

      return isMobile;
    }

    return false;
  }

  addDiv() {
    const div = this.renderer.createElement("div");
    this.renderer.setAttribute(div, "id", "duns-registered-div");
    this.renderer.appendChild(document.body, div);
  }

  private loadDnBScript() {
    const script = this.renderer.createElement("script");
    this.renderer.setAttribute(script, "type", "text/javascript");
    this.renderer.setAttribute(
      script,
      "src",
      "https://dunsregistered.dnb.com/drsus.js?duns=13c5a392396c7ccc414fc91d3b59c511&ProfileURL=https://www.dnb.com/business-directory/company-profiles.cree8_inc.13c5a392396c7ccc414fc91d3b59c511.html?referrer=DRS"
    );

    if (this.scriptContainer) {
      this.renderer.appendChild(this.scriptContainer.nativeElement, script);
    }
  }
}
