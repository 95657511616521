import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { Observable, of } from 'rxjs'
import { catchError } from 'rxjs/operators'

import { BebopConfigService } from './bebop-config.service'

@Injectable({
  providedIn: 'root',
})
export class ArchiveService {
  constructor(
    private bebopConfig: BebopConfigService,
    private http: HttpClient
  ) {}

  // temp = {
  //   bucketName: 'cree-dev-s3-sync',
  //   credentialId: '670d835b9b033bbaead4e557',
  //   maxKeys: 50,
  //   page: null,
  //   prefix: 'TO_CREE8/CREE8_UPLOAD_TEST_FILES/',
  // }
  listFiles(data?: any): Observable<any> {
    return this.http.post<any>(`${this.bebopConfig.apiUrl}/api/v1/s3/list_files`, data).pipe(
      catchError((error: any) => {
        console.error('On listFiles', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  listFolders(data?: any): Observable<any> {
    return this.http.post<any>(`${this.bebopConfig.apiUrl}/api/v1/s3/list_folders`, data).pipe(
      catchError((error: any) => {
        console.error('On listFolders', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  listBuckets(data?: any): Observable<any> {
    return this.http.post<any>(`${this.bebopConfig.apiUrl}/api/v1/s3/list_buckets`, data).pipe(
      catchError((error: any) => {
        console.error('On listBuckets', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }
}
