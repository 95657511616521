import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { RocketBusy, RocketSession, UiUploadFile } from 'src/app/components/rocket/common/classes/rocket-types'
import { Project } from 'src/app/models/bebop.model'
import { RocketTransferListRequest } from 'src/app/models/rocket-rest.model'
import { BebopConfigService } from 'src/app/services/bebop-config.service'
import { ElectronService } from 'src/app/services/electron.service'
import { ElasticSearchService } from 'src/app/services/rocket/elastic-search.service'

import { createInitialState, createUploadSession, UploaderState, UploaderStore } from './uploader.store'

@Injectable({ providedIn: 'root' })
export class UploaderService implements RocketBusy {
  constructor(
    private ustore: UploaderStore,
    private http: HttpClient,
    private bebopConfig: BebopConfigService,
    private electronService: ElectronService,
    public es: ElasticSearchService
  ) {}

  forceLogout() {
    this.ustore.update((store) => createInitialState())
  }

  createUploadInstance(rocket: any, project: Project, props?: Partial<RocketSession<any, UiUploadFile>>) {
    let uploader = createUploadSession(rocket, project, props)
    this.ustore.update((store) => ({
      ...store,
      uploads: [...store.uploads, uploader],
    }))

    return uploader
  }

  setSelectedProjectDetails(project: Project = null, selectedUpload: RocketSession<any, UiUploadFile> = null) {
    this.ustore.update((store) => ({
      ...store,

      projectSelected: !!project,
      // select same project again for force update
      selectedProject: project ? { ...project } : null,
      selectedUpload,
    }))
  }

  updateRocketSession(upload: RocketSession<any, UiUploadFile>, props: Partial<RocketSession<any, UiUploadFile>>) {
    this.ustore.update((store) => {
      let uploads = store.uploads?.filter((u) => u.id != upload.id)
      // Prevent old state issue
      let up = store.uploads?.find((u) => u.id == upload.id) ?? upload

      if (props?.rootPath && up?.rocket) {
        up.rocket.opts.rootPath = props.rootPath
      }

      return {
        ...store,
        uploads: [
          ...uploads,
          {
            ...up,
            ...props,
          },
        ],
      }
    })
  }

  updateSession(upload: RocketSession<any, UiUploadFile>) {
    this.ustore.update((store) => {
      let uploads = store.uploads?.filter((u) => u.id != upload.id)

      return {
        ...store,
        uploads: [
          ...uploads,
          {
            ...upload,
          },
        ],
      }
    })
  }

  update(data: Partial<UploaderState>) {
    this.ustore.update((store) => ({
      ...store,
      ...data,
    }))
  }

  isBusy(session: RocketSession<any, UiUploadFile>) {
    return (
      session?.overallProgress &&
      !!(
        session?.overallProgress?.progressList.length ||
        session?.overallProgress?.nextList.length ||
        session?.overallProgress?.errorList.length ||
        session?.checksumFileList?.length
      )
    )
  }

  recentSessionDirectories(data: RocketTransferListRequest) {
    return this.es.recentSessionDirectories(data)
  }

  listPinnedFolders(data: RocketTransferListRequest) {
    return this.es.listPinnedFolders(data)
  }

  addPinnedFolder(data: RocketTransferListRequest) {
    return this.es.addPinnedFolder(data)
  }

  removePinnedFolder(data: RocketTransferListRequest) {
    return this.es.removePinnedFolder(data)
  }
}
