<div class="cree8-archive">
  <div class=" header-project">
    <div class="header-text">
      <div class="headline1">Projects</div>

      <div class="breadcrumb">
        <div class="breadcrumb-item" *ngFor="let segment of breadcrumbs; let i = index">
          <span class="breadcrumb-item-text body3" [class.last]="i === breadcrumbs.length - 1"
            (click)="i !== breadcrumbs.length - 1 ? onGoDirectory(segment) : null">
            {{ segment.name }}
          </span>
          <span *ngIf="i !== breadcrumbs.length - 1" class="breadcrumb-item-separator">/</span>
        </div>
      </div>

    </div>
  </div>

  <div class="tabs-panel">
    <cree8-tabs [tabs]="tabs" (tabClick)="onSelectHeaderTabs($event)"></cree8-tabs>
    <div class="actions-list">
      <actions-list  (refresh)="onRefresh()" (searchValue)="onSearchValue($event)"
        (sorting)="onSorting($event)" [allowedActions]="allowedActions" (created)="onCreatedProject()"
        (viewType)="onViewType($event)" [labelCreated]="'NEW PROJECT'" [listSorting]="listSortingProjects"
        [activedSort]="activeSorting" [activedViewType]="activedViewType"></actions-list>
    </div>
  </div>

  <div class="projects-container scrollbar" (scroll)="onScroll($event)">
    <cree8-archive-view #archiveViewComponent *ngIf="activeTab === 'files' && project" [breadcrumbs]="breadcrumbs"
      (breadcrumbChange)="onBreadcrumbChange($event)" [project]="project"></cree8-archive-view>
    <cree8-project-files-users *ngIf="activeTab === 'members'"
      [organization]="organization" #projectFilesUsersComponent></cree8-project-files-users>
    <cree8-archive-transfer-status #archiveTransferStatusComponent *ngIf="activeTab === 'transferStatus'" />
  </div>
</div>
