import { Component, Input, OnInit } from '@angular/core'

import { Subject, takeUntil } from 'rxjs'
import { BebopClientUtilsService } from 'src/app/services/bebop-client-utils.service'
import { ElasticSearchService } from 'src/app/services/rocket/elastic-search.service'
import { ArchiveQuery } from 'src/app/store/archive/archive.query'
import { SessionQuery } from 'src/app/store/session/session.query.js'
import { UIQuery } from 'src/app/store/ui/ui.query.js'

@Component({
  selector: 'cree8-archive-transfer-status',
  styleUrl: './archive-transfer-status.component.scss',
  templateUrl: './archive-transfer-status.component.html',
})
export class ArchiveTransferStatusComponent implements OnInit {
  private destroy$ = new Subject<void>()

  files = []
  size = 25
  offset = 0
  addSize = 25
  total = 0

  fileStatus = {
    Cancelled: 4,
    Completed: 6,
    Downloading: 7,
    Error: 5,
    Paused: 3,
    Queued: 0,
    Staged: 8,
    Started: 1,
    Uploading: 2,
    Verifying: 9,
  }

  statusNames = [
    'Queued',
    'Started',
    'Uploading',
    'Paused',
    'Cancelled',
    'Error',
    'Completed',
    'Downloading',
    'Staged',
    'Verifying',
  ]

  syncDirections = ['', '⬆ UP', '⬇ DOWN']
  isLoading = false
  isLoadingMore = false
  searchText = ''
  constructor(
    private elasticSearchService: ElasticSearchService,
    private sessionQuery: SessionQuery,
    private archiveQuery: ArchiveQuery,
    private clientUtils: BebopClientUtilsService,
    private uiQuery: UIQuery
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  ngOnInit(): void {
    this.isLoading = true
    this.searchText = ''
    this.getFileTransfer(0, 25)
  }

  private getFileTransfer(offset: number, size: number) {
    this.uiQuery
      .getSelectedOrg()
      .pipe(takeUntil(this.destroy$))
      .subscribe((org) => {
        if (!org) {
          return
        }
        this.sessionQuery
          .getUser()
          .pipe(takeUntil(this.destroy$))
          .subscribe((user) => {
            this.archiveQuery
              .selectProject()
              .pipe(takeUntil(this.destroy$))
              .subscribe((project) => {
                const data = {
                  archive: true,
                  channel: 3,
                  downloadOnly: false,
                  offset: offset,
                  orgs: [org._id],
                  size: size,
                  sort: [{ lastUpdated: 'desc' }],
                  status: [1, 2, 3, 4, 5, 6, 7, 8, 9],
                  uploadOnly: false,
                  userId: user._id,
                  view: 1,
                  ...(this.searchText?.length > 1 && {
                    search: { field: 'file', value: this.searchText },
                  }),
                }

                this.elasticSearchService
                  .getCompleted({
                    data: data,
                  })
                  .pipe(takeUntil(this.destroy$))
                  .subscribe((response: any) => {
                    if (this.isLoading) {
                      const loadFile = response.hits
                      const mapFile = loadFile.map((file) => {
                        let paths = file.file?.split('/')
                        return {
                          ...file,
                          file: paths.pop() || paths.pop() + '/',
                          readableSize: this.clientUtils.readablizeBytes(file.size),
                        }
                      })

                      this.files = [...this.files, ...mapFile]

                      this.isLoadingMore = false
                    } else {
                      this.files = response.hits
                      this.total = response.total
                      this.files = this.files.map((file) => ({
                        ...file,
                        readableSize: this.clientUtils.readablizeBytes(file.size),
                      }))
                    }

                    this.isLoading = false
                  })
              })
          })
      })
  }

  calculateStrokeDashArray(percentage: number): string {
    const radius = 16 // Same as 'r' in the circle
    const circumference = 2 * Math.PI * radius // Circle circumference
    const progress = (percentage / 100) * circumference
    return `${progress} ${circumference}` // Remaining part of the circle
  }

  onRefresh() {
    this.size = 25
    this.ngOnInit()
  }

  onSearchValue(value: string) {
    this.isLoading = true
    this.searchText = value
    this.getFileTransfer(0, 25)
  }

  onLoadMore() {
    if (!this.isLoadingMore && this.files.length < this.total) {
      this.isLoadingMore = true
      this.loadMore()
    }
  }

  private loadMore() {
    this.offset += this.addSize
    this.isLoading = true
    this.getFileTransfer(this.offset, this.addSize)
  }
}
