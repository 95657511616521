import { Injectable } from '@angular/core'

import { ArchiveStore } from './archive.store'

@Injectable({ providedIn: 'root' })
export class ArchiveService {
  constructor(private archiveStore: ArchiveStore) {}

  setProject(project: any) {
    this.archiveStore.update({ project })
  }

  updateSearch(searchParams) {
    this.archiveStore.update({ searchParams })
  }
  // Method to update only the prefix in searchParams
  updatePrefix(prefix: string) {
    this.archiveStore.update((state) => ({
      searchParams: {
        ...state.searchParams,
        prefix, // Update only the prefix while keeping other properties intact
      },
    }))
  }

  updateActiveTab(activeTab: string) {
    this.archiveStore.update({ activeTab })
  }

  clear() {
    this.archiveStore.reset()
  }
}
