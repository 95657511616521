import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

import { catchError, throwError } from 'rxjs'
import { UiDownloadFile } from 'src/app/components/rocket/common/classes/rocket-types'
import { RocketService } from 'src/app/services/rocket/rocket.service'

import { ICardActionsList } from '../card-actions-list/card-actions-list.component'

export type IFileAction =
  | 'delete'
  | 'receive-link'
  | 'share'
  | 'rename'
  | 'media-info'
  | 'download-file'
  | 'detail'
  | 'archive'

@Component({
  selector: 'cree8-card-file',
  styleUrl: './card-file.component.scss',
  templateUrl: './card-file.component.html',
})
export class CardFileComponent implements OnInit {
  @Input() type: string = 'grid'
  @Input() isArchive: boolean = false
  @Input() isFirst: boolean
  @Input() isLast: boolean
  @Input() file: UiDownloadFile = {} as UiDownloadFile
  @Input() cover: string = 'assets/img/pre-set-up-cover.svg'
  @Input() activeProject: any
  @Output() actionFile: EventEmitter<{
    type: ICardActionsList
    data: any
  }> = new EventEmitter<{ type: ICardActionsList; data: any }>()

  showActionButtons = false
  streamUrl: string = ''
  constructor(private rocketService: RocketService) {}

  ngOnInit() {}

  deleteFile(file: UiDownloadFile) {
    this.actionFile.emit({ data: file, type: 'delete' })
  }

  mappingIcon(type: string) {
    if (type === 'folder') {
      return `Folder_Single`
    }

    if (type === 'audio' || type === 'video' || type === '') {
      if (this.viewSupportedVideoType()) {
        return `Play`
      }

      if (this.viewSupportedAudioType()) {
        return `Audio`
      }

      return `Generic_File`
    }

    return `Generic_File`
  }

  actionsList(): Array<ICardActionsList> {
    if (this.isArchive) {
      if (this.file.type === 'folder') {
        return ['retrieve']
      }

      const options: ICardActionsList[] = ['retrieve']
      return options
    } else {
      if (this.file.type === 'folder') {
        return ['receive-link', 'archive', 'rename', 'delete']
      }
      const options: ICardActionsList[] = ['download-file', 'share', 'archive', 'delete']
      if (this.showNotesLink(this.file.name)) {
        options.splice(3, 0, 'notes')
      }
      if (this.file.media) {
        options.push('media-info')
      }
      return options
    }
  }

  showNotesLink(fileName: string) {
    const revTypes = ['mp4', 'mov', 'mkv', 'wav', 'mp3']
    const extension = fileName.toLowerCase().split('.').pop()

    return revTypes.indexOf(extension) != -1
  }

  viewSupportedVideoType() {
    const ext = this.file.name.toLowerCase().split('.').pop()
    return ['mp4', 'mov'].indexOf(ext) != -1
  }

  viewSupportedAudioType() {
    const ext = this.file.name.toLowerCase().split('.').pop()
    return ['mp3', 'wav', 'aac', 'aacp', 'flac', 'caf'].indexOf(ext) != -1
  }

  onAction(props: { action: ICardActionsList; data: any }) {
    if (this.file.name.toLowerCase().split('.').pop() === 'mxf') {
      if (props.action === 'detail') return
      else this.actionFile.emit({ data: this.file, type: props.action })
    } else {
      if (this.notAllowPlayer()) {
        return
      }

      this.actionFile.emit({ data: this.file, type: props.action })
    }
  }

  notAllowPlayer() {
    return (
      (this.file.type === 'video' || this.file.type === 'audio') &&
      !this.viewSupportedVideoType() &&
      !this.viewSupportedAudioType()
    )
  }

  onOpenDropdown(file: UiDownloadFile) {
    if (!file.ui) file.ui = { dropDownActive: true }
    else file.ui.dropDownActive = true
  }

  onCloseDropdown(file: UiDownloadFile) {
    if (!file.ui) file.ui = { dropDownActive: false }
    else file.ui.dropDownActive = false
  }

  getStreamMedia(file: any) {
    this.rocketService
      .downloadFile(file)
      .pipe(
        catchError((error) => {
          return throwError(() => new Error(error))
        })
      )
      .subscribe({
        error: (err) => {
          console.error('Error downloading video:', err)
        },
        next: (res) => {
          this.streamUrl = res
        },
      })
  }
}
