import { Component, Inject, OnInit } from '@angular/core'

import { Observable, of } from 'rxjs'
import { ModalOverlayRef } from 'src/app/common/classes/modal-overlay-ref'
import { ToastService } from 'src/app/common/components/toast/toast.service'
import { BEBOP_MODAL_DATA, OnExternalModalClose } from 'src/app/common/services/component-modal.service'
import { PageSizes, Pagination } from 'src/app/common/types'
import { BebopStorage, Pod, Project } from 'src/app/models/bebop.model'
import { IProjectDetail } from 'src/app/models/projects.model'
import { AddProjectResponse, StoragesResponse } from 'src/app/models/response.model'
import { UiPod, UiStorage } from 'src/app/models/ui.model'
import { ProjectsQuery } from 'src/app/store/projects/projects.query'
import { SessionService } from 'src/app/store/session/session.service'

export class AddProjectModalAction {
  name: 'Cancel' | 'Add'
  payload?: Project
}

export type SelectDrive = { name: string; selected: boolean }

@Component({
  selector: 'bebop-add-project-modal',
  styleUrls: ['./add-project-modal.component.scss'],
  templateUrl: './add-project-modal.component.html',
})
export class AddProjectModalComponent implements OnInit, OnExternalModalClose {
  project: Project

  loading = false

  formErrors = {
    driveLetter: '',
    folderLabel: '',
    name: '',
    pod: '',
    storage: '',
  }

  filteredPodCount = 0

  pods: UiPod[] = []
  storages: UiStorage[] = []
  listProjects: IProjectDetail[] = []
  projectDrivesLetter: string[] = []

  drives: SelectDrive[] = Array.from({ length: 22 }).map((m, i) => ({
    name: String.fromCharCode(65 + 4 + i),
    selected: false,
  }))

  pageOptionsStorage: Pagination = {
    page: 1,
    size: PageSizes[2],
    total: 0,
  }

  pageOptionsPod: Pagination = {
    page: 1,
    size: PageSizes[2],
    total: 0,
  }

  bindings: {
    name: string
    folderLabel: string
    driveLetter: string
    storage: UiStorage
    pod: UiPod
  } = {
    driveLetter: '',
    folderLabel: '',
    name: '',
    pod: null,
    storage: null,
  }

  constructor(
    public ref: ModalOverlayRef<AddProjectModalComponent, AddProjectModalAction>,
    @Inject(BEBOP_MODAL_DATA) public data: any,
    private toastService: ToastService,
    private sessionService: SessionService,
    private projectsQuery: ProjectsQuery
  ) {
    this.onPodSearch = this.onPodSearch.bind(this)
    this.toUiPod = this.toUiPod.bind(this)
    this.toUiStorage = this.toUiStorage.bind(this)
  }

  ngOnInit(): void {
    this.bindings.driveLetter = this.drives[0].name
    this.fetchPods()
    this.projectsQuery.getListProjects().subscribe({
      error: (error) => {
        console.log(error, 'Fetch projects')
        this.listProjects = []
      },
      next: (projects) => {
        this.listProjects = projects
      },
    })
  }

  cancel() {
    this.ref.send({ name: 'Cancel' })
    this.ref.close()
  }

  applyRules(_for: 'folderLabel' | 'name' | 'pod' | 'driveLetter' | 'all' = 'all') {
    let forAll = _for == 'all'
    if (forAll || _for == 'folderLabel') {
      if (this.formErrors.folderLabel && this.bindings.folderLabel?.trim()) {
        this.formErrors.folderLabel = ''
      }

      if (this.bindings.folderLabel.match(/[^\w]/i)) {
        this.formErrors.folderLabel = 'Folder label: No special characters allowed.'
      } else if ((this.bindings.folderLabel?.trim?.()?.length ?? 0) > 20) {
        this.formErrors.folderLabel = 'Folder label: Max length is 20 characters.'
      } else if (!this.bindings.folderLabel?.trim()) {
        this.formErrors.folderLabel = 'Folder label can not be empty.'
      } else if (/[A-Z]/.test(this.bindings.folderLabel)) {
        this.formErrors.folderLabel = 'Folder label: No capital letters allowed.'
      } else {
        this.formErrors.folderLabel = ''
      }
    }

    if (forAll || _for == 'name') {
      let tname = this.bindings.name?.trim()
      // special characters allowed ?
      if (!tname) {
        this.formErrors.name = 'Name can not be empty.'
      } else if ((tname?.length ?? 0) > 50) {
        this.formErrors.name = 'Name: Max length is 50 characters.'
      } else if ((tname?.length ?? 0) < 5) {
        this.formErrors.name = 'Name: Min length is 5 characters.'
      } else {
        this.formErrors.name = ''
      }
    }

    if (forAll || _for == 'pod') {
      this.formErrors.pod = this.bindings.pod ? '' : 'Pod is not selected.'
    }

    if (forAll || _for == 'driveLetter') {
      this.formErrors.driveLetter = this.drives.some((d) => d.selected) ? '' : 'Drive letter is not selected.'
    }
  }

  validate() {
    this.applyRules()

    if (this.formErrors.name) {
      this.toastService.show({
        text: this.formErrors.name,
        type: 'error',
      })
      return false
    }

    if (this.formErrors.pod) {
      this.toastService.show({
        text: this.formErrors.pod,
        type: 'error',
      })
      return false
    }

    if (this.formErrors.folderLabel) {
      this.toastService.show({
        text: this.formErrors.folderLabel,
        type: 'error',
      })

      return false
    }

    if (this.formErrors.driveLetter) {
      this.toastService.show({
        text: this.formErrors.driveLetter,
        type: 'error',
      })
      return false
    }

    return true
  }

  addProject() {
    if (!this.validate()) return

    this.loading = true

    // org is passed from the context
    this.sessionService
      .addProject({
        folderLabel: this.bindings.folderLabel,
        folderName: this.bindings.folderLabel,
        name: this.bindings.name,
        pod: this.bindings?.pod,
        preferredLetter: this.bindings.driveLetter,
        storage: this.bindings?.storage,
      })
      .subscribe((e: AddProjectResponse) => {
        this.loading = false

        if (e.error) {
          console.log(e.error, 'Add project')
          this.toastService.show({
            text: e.error.msg || 'Unable to add project',
            type: 'error',
          })

          return
        }

        this.toastService.show({
          text: e.msg || 'Project has been saved',
          type: 'success',
        })

        this.project = e.project

        this.ok()
      })
  }

  ok() {
    this.ref.send({ name: 'Add', payload: this.project })
    this.ref.close()
  }

  onSelectDrive(dr: SelectDrive) {
    this.drives.forEach((d) => (d.selected = d.name == dr.name))
    this.bindings.driveLetter = dr.name
  }

  onKeyupProjectName(ev: KeyboardEvent) {
    this.applyRules('name')
  }

  onKeyupFolderLabel(ev: KeyboardEvent) {
    this.applyRules('folderLabel')
  }

  searchStorage = ''
  filteredStorageCount = 0

  toUiStorage(storage: BebopStorage) {
    if (!storage) return null
    return {
      _id: storage._id,
      displayName: storage.displayName,
      iname: storage.name?.toLowerCase(),
      name: storage.name,
      selected: this.bindings.storage?._id == storage._id,
      source: storage,
    }
  }

  get filteredStorages() {
    let searchText = this.searchStorage?.toLowerCase() ?? ''
    let storages = searchText ? this.storages.filter((p) => p?.iname?.indexOf(searchText) != -1) : this.storages
    this.filteredStorageCount = storages?.length || 0

    return storages
  }

  get isLoading() {
    return this.loading || this.loadingStorage
  }

  onStorageSearch(value: string): Observable<string[]> {
    this.searchStorage = value
    return of(this.filteredStorages.map((p) => p.name))
  }

  onDropdownStorageChange(e: 'Open' | 'Close') {
    this.searchStorage = ''
  }

  onSelectStorage(st: UiStorage) {
    this.searchStorage = ''
    this.filteredStorageCount = this.storages?.length

    this.bindings.storage = st
  }

  loadingStorage = false
  fetchStorage() {
    this.loadingStorage = true
    this.sessionService
      .getStoragesByPod({
        // local search
        podId: this.bindings.pod?._id,
        searchText: '',
      })
      .subscribe((e: StoragesResponse) => {
        this.loadingStorage = false
        if (e.error) {
          console.log(e.error, 'Fetch storages')
          this.toastService.show({
            text: 'Unable to fetch storages',
            type: 'error',
          })
          this.storages = []
          return
        }

        this.storages = e?.map(this.toUiStorage) ?? []

        if (!this.bindings.storage) {
          // All
          this.bindings.storage = this.storages[0]
          return
        }
      })
  }

  searchPod = ''

  toUiPod(pod: Pod) {
    if (!pod) return null
    return {
      _id: pod._id,
      iname: pod.name?.toLowerCase(),
      location: '',
      name: pod.name,
      projectsCount: 0,
      selected: this.bindings.pod?._id == pod._id,
      source: pod,
    }
  }

  get filteredPods() {
    let searchText = this.searchPod?.toLowerCase() ?? ''
    let pods = searchText ? this.pods.filter((p) => p?.iname?.indexOf(searchText) != -1) : this.pods
    this.filteredPodCount = pods?.length || 0

    return pods
  }

  onPodSearch(value: string): Observable<string[]> {
    this.searchPod = value
    return of(this.filteredPods.map((p) => p.name))
  }

  onDropdownPodChange(e: 'Open' | 'Close') {
    this.searchPod = ''
  }

  onSelectPod(pod: UiPod) {
    this.searchPod = ''
    this.filteredPodCount = this.pods?.length

    let opod = this.bindings.pod
    this.bindings.pod = pod
    this.mappingDriveLetter(pod)

    if (!pod) {
      this.resetStorage()
      return
    }

    if (pod?._id != opod?._id) {
      this.resetAndFetchStorage()
    }
  }

  resetAndFetchStorage() {
    this.resetStorage()
    this.fetchStorage()
  }

  resetStorage() {
    this.bindings.storage = null
    this.storages = []
    this.filteredStorageCount = 0
    this.searchStorage = ''
  }

  fetchPods() {
    this.loading = true
    this.sessionService
      .filterPods({
        // local search
        pageOptions: this.pageOptionsPod,
        searchText: '',
      })
      .subscribe((e) => {
        this.loading = false
        if (e.error) {
          console.log(e.error, 'Fetch pods')
          this.toastService.show({
            text: 'Unable to fetch pods',
            type: 'error',
          })
          this.pods = []
          return
        }

        this.pods = e.data?.map(this.toUiPod) ?? []

        if (!this.bindings.pod) {
          // All
          this.bindings.pod = this.pods[0]
          this.mappingDriveLetter(this.bindings.pod)
          this.resetAndFetchStorage()
          return
        }
      })
  }

  mappingDriveLetter(pod: UiPod) {
    const preferredLetters = this.listProjects.filter((p) => p.pod?._id === pod._id).map((p) => p.preferredLetter)

    let found = false

    this.drives = this.drives.map((drive) => {
      if (!found && !preferredLetters.includes(drive.name)) {
        found = true
        return { ...drive, selected: true }
      }
      return { ...drive, selected: false }
    })

    // Set the default drive letter to the first selected drive
    this.bindings.driveLetter = this.drives.find((drive) => drive.selected)?.name || this.drives[0].name
    this.projectDrivesLetter = this.listProjects.filter((p) => p.pod?._id == pod._id).map((p) => p.preferredLetter)
  }
}
