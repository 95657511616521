<div class="live-files-container">
  <table class="cree8-table" *ngIf="liveFiles?.length > 0; else noLiveFiles">
    <thead>
      <tr>
        <th>Actions</th>
        <th>Status</th>
        <th>Source</th>
        <th>Project name</th>
        <th>Size</th>
        <th>Size Last Modified</th>
      </tr>
    </thead>
    <tbody class="scrollbar">
      <tr *ngFor="let lfile of liveFiles">
        <td>
          <div class="td-actions">

            <cree8-btn-icon *ngIf="lfile.hlsUrl" (click)="copyHLSURL(lfile.hlsUrl)">
              <svg class="ico" viewBox="0 0 24 24">
                <use [attr.xlink:href]="'app/common/assets/icons/_sprite.svg#Link'"></use>
              </svg>
            </cree8-btn-icon>

            <div *ngIf="project || lfile.status !== 'STOPPED'">
              <button class="btn-live" (click)="startPipeline(lfile, $event)" *ngIf="lfile.status === 'STOPPED'" [disabled]="disableStart">
                <svg class="icon" [ngClass]="generateClass(lfile)" viewBox="0 0 18 18" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <circle class="outer" cx="9" cy="9" r="8" stroke="currentColor" />
                  <circle class="inner" cx="9" cy="9" r="6" fill="currentColor" />
                </svg>
                <span>Start</span>
              </button>
              <button class="btn-live" *ngIf="lfile.status !== 'STOPPED'" (click)="stopPipeline(lfile)">
                <svg class="icon" [ngClass]="generateClass(lfile)" viewBox="0 0 18 18" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <circle class="outer" cx="9" cy="9" r="8" stroke="currentColor" />
                  <circle class="inner" cx="9" cy="9" r="6" fill="currentColor" />
                </svg>
                <span *ngIf="lfile.status === 'STOPPING'">Stopping…</span>
                <span
                  *ngIf="lfile.status === 'ERROR' || lfile.status === 'STARTED' || lfile.status === 'LIVE'  || lfile.status === 'STARTING'">Stop</span>
              </button>
            </div>
          </div>
        </td>
        <td>
          <span class="capitalize">{{lfile.status.toLowerCase()}} {{lfile.statusMessage ? ' - ': ''}}
            {{lfile.statusMessage}}</span>
        </td>
        <td>{{lfile.name}}</td>
        <td> <ng-template #textOnly>{{ lfile.project.name }}</ng-template>
          <span *ngIf="enableLink; else textOnly" class="projectName" (click)="detailProject(lfile.project._id)">
            {{ lfile.project.name }}
          </span>
        </td>
        <td>{{lfile.size}}</td>
        <td>{{lfile.mtime}}</td>
      </tr>
    </tbody>
  </table>

  <!-- Template for when there are no invites -->
  <ng-template #noLiveFiles>
    <no-data [title]="'No live files'" [icon]="'Notes'"
      [description]="'You\'ll see all live files created across different projects here.'"></no-data>
  </ng-template>
</div>

<bebop-loader [status]="'Loading'" [rounded]="false" *ngIf="loading"></bebop-loader>
