import { Component, EventEmitter, Input, Output } from '@angular/core'

export type ICardActionsList =
  | 'receive-link'
  | 'media-info'
  | 'download-file'
  | 'share'
  | 'delete'
  | 'copy'
  | 'rename'
  | 'detail'
  | 'link'
  | 'settings'
  | 'members'
  | 'notes'
  | 'archive'
  | 'retrieve'

@Component({
  selector: 'cree8-card-actions-list',
  styleUrl: './card-actions-list.component.scss',
  templateUrl: './card-actions-list.component.html',
})
export class CardActionsListComponent {
  @Input() data: any
  @Input() actionsList: ICardActionsList[] = []
  @Input() type: 'file' | 'folder' | 'project' = 'file'
  @Input() active: boolean = false
  @Output() action: EventEmitter<{
    action: ICardActionsList
    data: any
  }> = new EventEmitter<{
    action: ICardActionsList
    data: any
  }>()
  @Output() closeDropdown: EventEmitter<any> = new EventEmitter<any>()
  @Output() openDropdown: EventEmitter<any> = new EventEmitter<any>()

  buildDeleteLabel() {
    return `Delete ${this.type.charAt(0).toUpperCase() + this.type.slice(1)}`
  }

  onAction(action: ICardActionsList) {
    this.action.emit({ action, data: this.data })
  }

  onCloseDropdown(data: any) {
    this.closeDropdown.emit(data)
  }

  onOpenDropdown(data: any) {
    this.openDropdown.emit(data)
  }
}
