import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { Observable, of } from 'rxjs'
import { catchError } from 'rxjs/operators'
import {
  RocketTransferListRequest,
  RocketTransferListResponse,
  RocketTransferRequest,
  RocketTransferResponse,
} from 'src/app/models/rocket-rest.model'

import { BebopConfigService } from '../bebop-config.service'

@Injectable({
  providedIn: 'root',
})
export class ElasticSearchService {
  uploadRecent: (d: RocketTransferListRequest) => Observable<Partial<RocketTransferListResponse>>
  downloadRecent: (d: RocketTransferListRequest) => Observable<Partial<RocketTransferListResponse>>
  uploadBatch: (d: RocketTransferRequest) => Observable<Partial<RocketTransferResponse>>
  uploadSession: (d: RocketTransferRequest) => Observable<Partial<RocketTransferResponse>>
  getCompleted: (d: RocketTransferRequest) => Observable<Partial<RocketTransferResponse>>
  getUnverifiedList: (d: RocketTransferListRequest) => Observable<Partial<RocketTransferListResponse>>
  removePartialUploads: (d: RocketTransferRequest) => Observable<Partial<RocketTransferResponse>>
  updateSessionCancelled: (d: RocketTransferRequest) => Observable<Partial<RocketTransferResponse>>
  recentSessionDirectories: (d: RocketTransferListRequest) => Observable<Partial<RocketTransferListResponse>>
  listPinnedFolders: (d: RocketTransferListRequest) => Observable<Partial<RocketTransferListResponse>>
  addPinnedFolder: (d: RocketTransferRequest) => Observable<Partial<RocketTransferResponse>>
  removePinnedFolder: (d: RocketTransferRequest) => Observable<Partial<RocketTransferResponse>>

  listDirectory: (d: RocketTransferListRequest) => Observable<Partial<RocketTransferListResponse>>
  listByIds: (d: RocketTransferListRequest) => Observable<Partial<RocketTransferListResponse>>
  getExcludeList: (d: RocketTransferListRequest) => Observable<Partial<RocketTransferListResponse>>

  constructor(
    private http: HttpClient,
    private bebopConfig: BebopConfigService
  ) {
    // Transfer log

    let logPayloadBuilder = (data: RocketTransferRequest) => ({
      data: {
        projectId: data.projectId,
        userId: data.userId,
      },
      payload: data.data,
    })

    let logPayloadSearchBuilder = (data: RocketTransferRequest) => ({
      data: data.data,
    })

    this.uploadRecent = this.esPostBuilder('/api/v2/elasticsearch/recentTransfers', logPayloadBuilder)
    this.downloadRecent = this.esPostBuilder('/api/v2/elasticsearch/recentTransfers', logPayloadBuilder)
    this.uploadBatch = this.esPostBuilder('/api/v2/elasticsearch/addBulk', logPayloadBuilder)
    this.uploadSession = this.esPostBuilder('/api/v2/elasticsearch/activeSession', logPayloadBuilder)
    this.getCompleted = this.esPostBuilder('/api/v2/elasticsearch/search', logPayloadSearchBuilder)
    this.getUnverifiedList = this.esPostBuilder('/api/v2/elasticsearch/pendingVerify', logPayloadBuilder)
    this.removePartialUploads = this.esPostBuilder('/api/v2/elasticsearch/removeIncomplete', logPayloadBuilder)
    this.updateSessionCancelled = this.esPostBuilder('/api/v2/elasticsearch/cancelSession', logPayloadBuilder)
    this.recentSessionDirectories = this.esPostBuilder(
      '/api/v2/elasticsearch/recent-upload-directories',
      logPayloadBuilder
    )

    this.listPinnedFolders = this.esPostBuilder('/api/v2/elasticsearch/listPinnedFolders')
    this.removePinnedFolder = this.esPostBuilder('/api/v2/elasticsearch/removePinnedFolder')
    this.addPinnedFolder = this.esPostBuilder('/api/v2/elasticsearch/addPinnedFolder')

    // bebop data service
    this.listDirectory = this.esPostBuilder('/api/v2/elasticsearch/listDirectory')
    this.listByIds = this.esPostBuilder('/api/v2/elasticsearch/listByIds')
    this.getExcludeList = this.esPostBuilder('/api/v2/elasticsearch/listByQueueIds')
  }

  esPostBuilder<T, S>(suffix: string, builder: (d: T) => any = (d) => ({ data: d })) {
    let self = this
    return (data: T) => {
      let url = this.bebopConfig.apiUrl + suffix

      return self.http.post<S>(url, builder(data)).pipe(
        catchError((error: any) => {
          console.error(`On ${suffix}`, error.message)
          return of({
            error: {
              msg:
                error?.error?.msg || error?.error?.error?.msg || error?.error?.message || (error?.length ? error : ''),
            },
          })
        })
      )
    }
  }
}
